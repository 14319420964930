import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { logEventHub } from '../utils';
import CloseIcon from '@mui/icons-material/Close';
import '../../../stylesheets/triagebot/SymptomSearchMenu.scss';

let searchPromise;
const azureAPI = axios.create({
    baseURL: process.env.REACT_APP_SYMPTOM_BODY_URL
});

//******************************//

const SymptomSearchMenu = ({ message, showMenu, onClose, bodyPart, bodyPartQueryTerm, age, gender, tags, setTags, setBodyPartHover }) => {
    const [loadState, setLoadState] = useState('INIT');
    const [suggestions, setSuggestions] = useState([]);
    const [selection, setSelection] = useState(-1);
    const [show, setShow] = useState(showMenu);

    const helpText = (message?.data[0]?.trySearch)
        ? message?.data[0]?.trySearch
        : ""

    const asyncHBID = message?.AsyncHBID || 'Correlation-Id';

    useEffect(() => {
        if (!bodyPartQueryTerm) return;

        Object.assign(azureAPI.defaults, {
            headers: {
                "Content-Type": "application/json",
                "Correlation-Id": asyncHBID
            }
        });

        setLoadState('LOADING');

        searchPromise = azureAPI.post(
            "",
            {
                "body_part": bodyPartQueryTerm,
                "sex": gender,
                "age_in_years": age
            }
        )

        const localPromise = searchPromise;
        // NOTE: This works for single instances only. 
        searchPromise.then(response => {
            if (searchPromise === localPromise) {
                setLoadState("DONE");
                setSuggestions(response?.data?.data || []);
            }
        })
            .catch(function (error) {
                logEventHub('Failed request symptom menu search.', 'FE - symptom search menu');

                if (searchPromise === localPromise) {
                    setLoadState("DONE");
                    setSuggestions([]);
                }
            });

        setShow(showMenu);
    }, [age, bodyPartQueryTerm, gender, bodyPart, showMenu])

    const filteredSymptoms = () => {
        const listed = {};

        return suggestions.filter(symptom => {
            const alreadyListed = tags.find(tag => symptom.id === tag.id);
            listed[symptom.id] = true;

            return !alreadyListed;
        });
    }

    const selectSymptom = (symptom) => {
        setTags([...tags, symptom]);
        onClose();
    }

    const sentenceCase = (text) => {
        if (!text) return text;

        return text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase();
    }

    useEffect(() => {
        if (!show)
            return;

        if (selection !== 0 && !selection)
            return;

        if (typeof setBodyPartHover !== 'function')
            setBodyPartHover = () => { };

        if (!suggestions) {
            setBodyPartHover("");
            return;
        }

        if (selection < 0 || selection > (suggestions.length - 1)) {
            setBodyPartHover("");
            return;
        }

        setBodyPartHover(suggestions[selection].body_parts[0]);
    })


    return (
        show
            ?
            <div className='symptom-menu' >
                <div
                    onClick={onClose}
                    className='closer'>
                </div>
                <div className='header' style={{ marginTop: '0em' }}>
                    <h2 className='headerText'>{bodyPart}</h2>
                    <button title='Close dialog' className='button-close' onClick={onClose}>
                        <CloseIcon
                            className='close'
                            role="img" aria-labelledby="close-label"
                            aria-hidden={false}
                        />
                    </button>
                </div>
                <div className='body'>
                    {
                        (loadState === 'LOADING')
                            ?
                            <div className='loading'>
                                {
                                    Array.from({ length: 3 }, (x, i) => {
                                        return (
                                            <div key={'waitbar' + i} className='loadAnim' style={{ opacity: 1 - (i * .22) }}>
                                                <div className='anim'>&nbsp;</div>
                                            </div>)
                                    })
                                }
                            </div>
                            :
                            <ul>
                                {
                                    filteredSymptoms().map((symptom, i) => {
                                        return (
                                            <li
                                                key={symptom.id + "_" + i}
                                                className={i === selection ? 'selected suggestion' : 'suggestion'}
                                                onMouseEnter={() => { setSelection(i) }}
                                                onMouseLeave={() => { setSelection(-1) }}
                                                onClick={() => selectSymptom(symptom)}
                                                title={sentenceCase(symptom.name)}
                                            >
                                                {
                                                    sentenceCase(symptom.name)
                                                }
                                            </li>)
                                    })
                                }
                                <li>
                                    <div
                                        className='help'
                                        onClick={onClose}
                                    >
                                        {helpText}
                                    </div>
                                </li>
                            </ul>
                    }
                </div>
            </div>
            : <></>
    );
};

export default SymptomSearchMenu;