import React, { useState, useRef, useEffect } from 'react';
import Text from './TextMessage';
import Range from './RangeMessage';
import TextInput from './TextInputMessage';
import '../../../stylesheets/triagebot/Nps.scss';

const NPSMessage = ({ message, sendMessage, disabled }) => {
    const [cardShowing, setCardShowing] = useState(1);
    const [npsValue, setNpsValue] = useState(10);
    const [comment, setComment] = useState('');

    const showFeedbackCard = message && message.data[1] && message.data[1].feedbackCard;

    sendMessage = (typeof sendMessage === 'function')
        ? sendMessage
        : () => { }

    const cardMessages = message && Array.isArray(message.data) && message.data.length > 1
        ? message.data[1]
        : {}

    const npsMessage = cardMessages.npsCard
        ? cardMessages.npsCard
        : {}

    const feedbackMessage = cardMessages.feedbackCard
        ? cardMessages.feedbackCard
        : {}

    const thankYouMessages = cardMessages.thankYouCards
        ? cardMessages.thankYouCards
        : {}

    const BLANK_THANK_YOU = { type: 'textcard', text: 'feedback' };

    const defaultThankYou = thankYouMessages.find(message => message.thankYouType === 'feedback')

    const npsThankYou = thankYouMessages.find(message => message.thankYouType === 'nps')

    const noCommentThankYou = thankYouMessages.find(message => message.thankYouType === 'noComment')

    var thankYouMessage = defaultThankYou && defaultThankYou.card
        ? defaultThankYou.card
        : BLANK_THANK_YOU

    if (npsValue < 9)
        thankYouMessage = npsThankYou && npsThankYou.card
            ? npsThankYou.card
            : BLANK_THANK_YOU

    if (!comment)
        thankYouMessage = noCommentThankYou && noCommentThankYou.card
            ? noCommentThankYou.card
            : BLANK_THANK_YOU

    const sendingMessage = (type, text, value) => {
        if (type === 'go-back') {
            setCardShowing(value)
            return;
        }

        if (cardShowing === 1) {
            setNpsValue(text);
            if(showFeedbackCard !== undefined){
                setCardShowing(2);
            }
            else{
                let response = {
                    nps: npsValue,
                    comment : ""
                };
                sendMessage(type, JSON.stringify(response), response);
                sendMessage('nps-answered', true, true);
                setCardShowing(3);
            }
            return;
        }

        if (cardShowing === 2) {
            setComment(text);
            let response = {
                nps: npsValue,
                comment: text
            }
            sendMessage(type, JSON.stringify(response), response)
            sendMessage('nps-answered', true, true);
            setCardShowing(3);
            
            return;
        }
    }

    const messageRef = useRef();

    useEffect(() => {
        if (messageRef.current) {
            messageRef.current.scrollIntoView(
                {
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest'
                })
        }
        
    })

    return (
            <div className='nps card' ref={messageRef}>
                {
                    cardShowing<3 && showFeedbackCard !== undefined 
                    ?
                        <div className='status' style={{paddingBottom: "16px"}}>
                                <div className='indicator done'>&nbsp;</div>
                                <div className={cardShowing > 1 ? 'indicator done' : 'indicator'}>&nbsp;</div>
                        </div>
                    :
                        <></>
                }
                {(cardShowing === 3) && <Text message={thankYouMessage} />}
                {(cardShowing === 2) && <TextInput message={feedbackMessage} sendMessage={sendingMessage} disabled={disabled} />}
                {(cardShowing === 1) && <Range message={npsMessage} sendMessage={sendingMessage} npsValue={npsValue} setNpsValue={setNpsValue} disabled={disabled} />}
            </div>
    )
}

export default NPSMessage;