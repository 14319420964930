import React from 'react';
import { getMarkDown } from '../utils';
import { ReactComponent as OopsIcon } from '../../../assets/Alert.svg';
import { ReactComponent as TimeoutIcon } from '../../../assets/Alert.svg';
import { ReactComponent as ConnectionIcon } from '../../../assets/Connection.svg';
import Footer from './Footer';
import '../../../stylesheets/triagebot/Errors.scss';

const AlertMessage = ({ message, sendMessage, allowRestart }) => {
    var headerText = (message.data && message.data.length && message.data[0].headerText)
        ? message.data[0].headerText
        : ''

    allowRestart = (allowRestart && allowRestart.toUpperCase && (allowRestart.toUpperCase() === 'TRUE'));

    var isConnection = false;

    switch (message.type) {
        case 'timeout':
            message.icon = <TimeoutIcon className='timeout large' />
            headerText = '**Timeout Error**'
            message.text = "This experience has timed-out. Please refresh the page to start again."
            break;
        case 'connecterr':
            message.icon = <ConnectionIcon className='small' />
            headerText = '**Connection Error**'
            message.text = allowRestart
                ? "We're having trouble connecting. Please click **restart**."
                : "We're having trouble connecting. Please refresh the page to start again."
            isConnection = true;
            break;
        case 'oops':  // Symptom Checker server errors 
            message.icon = <OopsIcon className='alert' />
            break;
        default:     // Infermetica errors
            message.icon = <OopsIcon className='alert' />
            headerText = '**Oops!**'
            message.text = "Something went wrong on our end. We apologize for the inconvenience."
    }

    return (
        <div className='alert card'>
            <div className='content'>
                <div className='icon' style={isConnection ? { minWidth: '126px' } : {}}>
                    {message.icon}
                </div>
                <div>
                    <div className='header'>
                        {getMarkDown(headerText)}
                    </div>
                    <div className='body'>
                        {getMarkDown(message)}
                    </div>
                </div>
            </div>
            {
                allowRestart &&
                <Footer>
                    <button
                        className={'submit-button'}
                        onClick={() => sendMessage('restart', 'Reset', 'Reset')}
                    >
                        Refresh
                </button>
                </Footer>
            }
        </div>
    );
}

export default AlertMessage;