import React, { useEffect } from 'react';
import { getMarkDown } from '../utils';
import { ReactComponent as CloseIcon } from '../../../assets/toast-icon-close-gray.svg';
import { ReactComponent as InfoIcon } from '../../../assets/info-icon-google.svg';
import '../../../stylesheets/triagebot/Toast.scss';

//****************************************//

const ToastDialog = ({ message, close, onClose, fullscreen }) => {
    close = (close instanceof Function)
        ? close
        : () => { }

    onClose = (onClose instanceof Function)
        ? onClose
        : () => { }

    const type = message?.type.toLowerCase() || 'unknown';

    const header = (typeof message?.header === 'string')
        ? message.header
        : ''

    const subheader = (typeof message?.subheader === 'string')
        ? message.subheader
        : ''

    const body = message?.body || '';

    const keyPressed = (e) => {
        if (e.keyCode === 13 || e.keyCode === 32)
            return;

        if (e.keyCode === 27)
            handleClose();
    }

    const handleClose = () => {
        document.getElementById('toast').style.top = '100%';
        document.getElementById('toast-closer').style.opacity = '0';
        document.getElementById('toast-closer').style.display = 'none';
        onClose();

        setTimeout(() => {
            close();
        }, 500);
    }

    const updateSize = () => {
        const toastHeaderHeight = document.getElementById('toast-header').offsetHeight;
        const toastSubHeaderHeight = document.getElementById('toast-subheader').offsetHeight;
        const toastBodyHeight = document.getElementById('toast-body').offsetHeight;
        const toastTotalHeight = toastHeaderHeight + toastBodyHeight + toastSubHeaderHeight;
        
        const maxToastHeight = window.innerHeight;
        const paddingBottom =  window.getComputedStyle(document.getElementById('toast-body')).paddingBottom;
        const padding = Number(paddingBottom.slice(0,2))
        const toastHeight = (fullscreen || maxToastHeight < toastTotalHeight)
            ? maxToastHeight
            : toastTotalHeight;
        
        document.getElementById('toast').style.top = (maxToastHeight - toastHeight) + 'px';
        document.getElementById('toast-scroll-container').style.height = (toastHeight-toastHeaderHeight-toastSubHeaderHeight-padding) + 'px';
        
        if (type !== 'nps')
            document.getElementById('toast-closer').style.opacity = .15;
    }

    function handleTabbing(event) {
        if (event.key.toLowerCase() !== "tab") return;

        const target = event.target;
        const closeButton = document.getElementById('toast-close-button');
        const firstButton = document.getElementById('toast-area').querySelector('button');

        if (!firstButton) {
            // No buttons in toast-area. So, keep focus on close button.
            event.preventDefault();
            closeButton.focus();
            return;
        }

        if (event.shiftKey) {
            // Back tabbing
            if (target === firstButton) {
                // At the first tabbable button
                event.preventDefault();
                closeButton.focus();
            }
            return;
        }

        if (target === closeButton) {
            event.preventDefault();
            firstButton.focus();
        }
    }

    useEffect(() => {
        window.addEventListener('resize', updateSize);
        updateSize();
        document.getElementById('toast-closer').style.display = 'block';
        return () => window.removeEventListener('resize', updateSize);
    })

    useEffect(() => {
        (message.focus instanceof Function)
            ? message.focus()
            : document.getElementById('toast').focus();
    }, [message])

    return (
        <>
            <div
                id='toast-closer'
                className='toast-closer'
                onClick={handleClose}
            />
            <div id='toast'
                className={type}
                onKeyDown={handleTabbing}
            >
                <div id='toast-area' className={type}>

                    <div id='toast-header'>
                        {
                            (type === 'info') &&
                            <span className='info-icon'>
                                <InfoIcon style={{width : '21px', height: '21px', fill: 'var(--primary-color)'}} />
                            </span>
                        }
                        <h2 className='headerText' style={{ "margin" : "0px" }}> {getMarkDown(header)} </h2>
                    </div>
                    <div id='toast-subheader'>
                        {getMarkDown(subheader)}
                    </div>    
                    <div id='toast-scroll-container'>
                        <div id='toast-body' className={type}>
                            {
                                (typeof body === 'string')
                                    ? getMarkDown(body)
                                    : body
                            }
                        </div>
                    </div>
                </div>
                {
                    <button
                        id='toast-close-button'
                        onClick={handleClose}
                        onKeyDown={keyPressed}
                    >
                        <CloseIcon />
                    </button>
                }
            </div>
        </>
    );
}

export default ToastDialog;
