import React from 'react';
import styled from 'styled-components';
/********************************************* */

const Message = styled.div`
    position: relative;
    padding: 24px;
    border-radius: 6px;
    background-color: ${props=> props.background};
`

const HeaderText = styled.div`
        color: var(--primary-color);
        margin-bottom: 16px;
        font-weight: 600;
    `

const SubHeader = styled.div`
    color: var(--primary-color);
`

/********************************************* */

const SummaryThankYou = ({ message, HoverColor }) => {

    const header = (message?.data?.[5].thankYouCard?.data?.[0].headerText)
        ? message.data[5].thankYouCard.data[0].headerText
        : 'Thank you for using SympleNote to check your symptoms.#'

    const subHeader = (message?.data?.[5].thankYouCard?.text)
        ? message.data[5].thankYouCard.text
        : 'If your symptoms worsen or do not improve, contact your doctor.#'

    return (
        <Message background={HoverColor+'13'}>
            <HeaderText> {header} </HeaderText>
            <SubHeader> {subHeader} </SubHeader>
        </Message>
    );
}

export default SummaryThankYou;