import React from 'react';
import { getMarkDown } from '../utils';
import '../../../stylesheets/triagebot/SummarySummary.scss';

const SummarySummary = ({ message, sendMessage }) => {

    const summaryMessage = message?.data?.[2]?.summaryCardContent?.summaryText
        ? message.data[2].summaryCardContent.summaryText
        : "";

    const headings = message?.data?.[2]?.summaryCardContent?.summarySections?.length
        ? message.data[2].summaryCardContent.summarySections
        : ['', '', '', ''];

    const ageHeader = message?.data?.[2]?.summaryCardContent?.ageHeader
        ? message.data[2].summaryCardContent.ageHeader
        : "";

    const sexHeader = message?.data?.[2]?.summaryCardContent?.sexHeader
        ? message.data[2].summaryCardContent.sexHeader
        : "";

    const titles = [
        ageHeader,
        sexHeader
    ];

    const unknown = '';

    const data1 = (message && message.data && message.data.length > 0 && message.data[1])
        ? message.data[1]
        : {}

    const primary = data1.chief_complaints
        ? data1.chief_complaints
        : []

    const others = data1.secondary_complaints
        ? data1.secondary_complaints
        : []

    const risks = data1.present_risk_factors
        ? data1.present_risk_factors
        : []

    const age = data1.age
        ? data1.age
        : unknown

    const gender = data1.gender
        ? data1.gender
        : unknown

    const capFirst = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    return (
        <div className='summary'>
            <div className='message'>
                {getMarkDown(summaryMessage)}
            </div>

            {
                !primary.length ||
                <div className='section'>
                    <div className='heading' tabIndex="0">
                        <h2 className='headerText'>{headings[0]}</h2>
                    </div>
                    {
                        primary.map((symptom, key) => {
                            return <li className='symptom' key={'primary' + key}>{capFirst(symptom)}</li>;
                        })
                    }
                </div>
            }
            {
                !others.length ||
                <div className='section'>
                    <div className='heading' tabIndex="0">
                        <h2 className='headerText'>{headings[1]}</h2>
                    </div>
                    {
                        others.map((symptom, key) => {
                            return <li className='symptom' key={'others' + key}>{capFirst(symptom)}</li>;
                        })
                    }
                </div>
            }
            {
                !risks.length ||
                <div className='section'>
                    <div className='heading' tabIndex="0">
                        <h2 className='headerText'>{headings[2]}</h2>
                    </div>
                    {
                        risks.map((symptom, key) => {
                            return <li className='symptom' key={'risks' + key}>{capFirst(symptom)}</li>;
                        })
                    }
                </div>
            }

            <div className='section'>
                <div className='heading' tabIndex="0">
                    <h2 className='headerText'>{headings[3]}</h2>
                </div>
                <div className='data'>
                    <div>{titles[0] + ' : ' + age}</div>
                    <div>{titles[1] + ' : ' + gender}</div>
                </div>
            </div>

        </div>);
};

export default SummarySummary;
