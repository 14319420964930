import React from 'react';
import { getMarkDown } from '../utils';

const TextMessage = ({ message }) => {

    const header = (message.data && message.data.length)
        ? message.data[0].header || message.data[0].headerText
        : ''

    return (

        <div className='content' id='NpsThanks' role='region' aria-live='polite'>
            <div className='header'>
                <h2 className='headerText'>{getMarkDown(header)}</h2>
            </div>
            <div className='body'>
                {getMarkDown(message)}
            </div>
        </div>
    );
}

export default TextMessage;