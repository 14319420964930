import React, { useState,useEffect } from 'react';
import Footer from './Footer';
import { getMarkDown, useDetectMobile } from '../utils';
import '../../../stylesheets/triagebot/SymptomQuestion.scss';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid'; // Grid version 1



const SymptomQuestionCard = ({ message, sendMessage, disabled, setToastMessage, HoverColor }) => {
    const [submitted, setSubmitted] = useState(false);
    const [editing, setEditing] = useState(false);
    const { isMobile } = useDetectMobile();
    const [questionData, setQuestionData] = useState({unit:'',value:'',evidence:''});
    const placeholder='Enter Duration'

    const [input, setInput] = useState('');
    const [unit, setUnit] = useState('');

    const headerText = message?.data?.[0]?.headerText
        ? message.data[0].headerText
        : '';
    
    const subHeaderText = message?.data?.[0]?.subHeaderText
        ? message.data[0].subHeaderText
        : '';

    const durationUnits = message?.data?.[1]?.durationUnits
        ? message.data[1].durationUnits
        : '';
    
    const symptomId = message?.data?.[1]?.symptomId
        ? message.data[1].symptomId
        : '';
    
    const questionId = (message?.data?.[0]?.question_id)
        ? message.data[0].question_id
        : ''

    const regex = /[^\d+$]/;

    useEffect(() => {
       setQuestionData({ ...questionData, evidence: symptomId})
    },[]);
    
    const setDataInput = e => {
    setInput(e.target.value)
    setQuestionData({ ...questionData, value: e.target.value })
    }

    const setDataUnit = e => {
    setUnit(e.target.value)
    setQuestionData({ ...questionData, unit: e.target.value })
    }
        
    const submitPress = (event) => {
        if (submitted) {
            sendMessage('rewind', questionId, questionId);
            setSubmitted(false);
            setEditing(true);
            return;
        }
         setSubmitted(true);
        editing
            ? sendMessage('message', `##edit@${message.data[0].question_id}@${JSON.stringify(questionData)}`, `##edit@${message.data[0].question_id}@${questionData}`)
            : sendMessage('message', JSON.stringify(questionData), questionData);
    };

    const isDisabled = submitted || disabled;

    const isSubmittionDisabled = submitted || !input.length || !unit.length || unit==='select' ||input==='0' || regex.test(input) || (parseInt(input) ?? 0 ) < 0 ||isDisabled;

        return (
        <div className={
            (isDisabled ? 'disabled ' : '') +
            (isMobile ? 'mobile ' : 'desktop ') +
            'symptom-duration card'}
        >
            <div className={'content'}>
                <div
                    className='input-blocker'
                    style={submitted ? { display: "block" } : { display: "none" }}
                />
                {
                    //isMobile &&
                    <>
                        <div className='header'>{getMarkDown(headerText)}</div>
                        <div className='subheader'>{getMarkDown(subHeaderText)}</div>
                    </>
                }
                {
                <div className='body'>
               <Grid container spacing={2}>
  <Grid item  md={6} xs={12}>
  <TextField
                                id="symptom-questions" 
                                placeholder={placeholder}
                                size='small'
                                aria-invalid="false"
                                variant="outlined"
                                value={input}
                                onChange={e=>setDataInput(e)}
                                InputProps={{
                                    inputProps: { min: 0, maxLength: 4}
                                }}
                                className={disabled ? 'disabled symptom-question-search textbox' :
                                'symptom-question-search textbox'}
                                disabled={disabled}
                                type="number"
                    />
  </Grid>
  <Grid item md={6} xs={12}>
  <Box sx={{ minWidth: 80 }}>
                        <FormControl className='symptom-question-units'>
                            <InputLabel id="demo-simple-select-label">Units</InputLabel>
                            <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={unit}
                                        label="unit"
                                        onChange={e=>setDataUnit(e)}
                                        sx={{
                                            width: 225,
                                            position: 'relative'
                                        }}
                                        className="symptom-question-units"
                                    >
                            <MenuItem value="select">Select Unit</MenuItem>
                            {
                                            durationUnits.map((durUnit, dindex) => {
                                                return (
                                                    <MenuItem key={durUnit.value + dindex} value={durUnit.value} className="menu-item">{durUnit.displayText}</MenuItem>
                                                )}
                                
                            )}
                            </Select>
                        </FormControl>
                    </Box>
  </Grid>
  
</Grid>
           <br/>       
                   
                            
                </div>
                }
            </div>
            <Footer
                showChangeButton={questionId && submitted && !message.isLastMessage}
                onAccepted={submitPress}
                onDenied={() => sendMessage('fastforward')}
                disabled={disabled}
                divID={questionId}
            >
                    <button
                        className='submit-button'
                        onClick={submitPress}
                        disabled={isSubmittionDisabled}
                        style={{'visibility': submitted ? 'hidden': '', 'display': submitted ? 'none' : ''}}
                        aria-hidden={8 ? 'true': 'false'}
                    >
                        Next
                    </button>                
            </Footer>
        </div>
    );
    
}

export default SymptomQuestionCard;