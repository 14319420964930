import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { ReactComponent as SearchOutlinedIcon } from '../../../assets/icon_search.svg';
import { ReactComponent as CloseIcon } from '../../../assets/tag-close-icon.svg';
import { logEventHub, getMarkDown, useDetectMobile } from '../utils';
import '../../../stylesheets/triagebot/SymptomSearchText.scss';

let searchPromise;
const azureAPI = axios.create({
    baseURL: process.env.REACT_APP_SYMPTOM_TEXT_URL
});

const SymptomSearchMessage = ({ message, tags, setTags, showToast, setBodyPartHover, disabled, HoverColor }) => {
    const [loadState, setLoadState] = useState('IDLE');
    const [selection, setSelection] = useState(-1);
    const [suggestions, setSuggestions] = useState([]);
    const [search, setSearch] = useState('');

    const keyWasPressed = useRef('');

    const { isMobile } = useDetectMobile();

    const asyncHBID = message?.AsyncHBID || 'Correlation-Id';

    const age = (message?.data[0]?.userAge)
        ? message?.data[0]?.userAge
        : 50;

    const gender = (message?.data[0]?.userSex)
        ? message?.data[0]?.userSex
        : 'male';

    const headerText = (message?.data[0]?.headerText)
        ? message?.data[0]?.headerText
        : '';

    const instructions = (message?.text)
        ? message?.text
        : '';

    const noResults = (message?.data[0]?.noResults)
        ? message?.data[0]?.noResults
        : '';

    const newSearch = (message?.data[0]?.newSearch)
        ? message?.data[0]?.newSearch
        : '';

    const placeholder = (message?.data[0]?.placeHolder)
        ? message?.data[0]?.placeHolder
        : '';

    const addMore = (message?.data[0]?.addMore)
        ? message?.data[0]?.addMore
        : '';

    // const onlySix = (message?.data[0]?.onlySix)
    //     ? message?.data[0]?.onlySix
    //     : '';

    const inputDisabled = disabled;

    const handleKeyDown = (e) => {
        if (e.keyCode === 38 && selection > 0) {
            keyWasPressed.current = 'up';
            setSelection(selection - 1);
            e.preventDefault();
            return;
        }

        if (e.keyCode === 40 && selection < suggestions.length - 1) {
            keyWasPressed.current = 'down';
            setSelection(selection + 1);
            e.preventDefault();
            return;
        }

        if (e.keyCode === 13) {
            if (search) selectSymptom();
            e.preventDefault();
            return;
        }
    }

    const keyPressedOnTag = (e, tag) => {
        if (e.keyCode === 46 || e.keyCode === 8) {
            e.preventDefault();
            removeTag(tag);
            return;
        }
    }

    const handleChange = (e) => {
        setSelection(0);

        if (!e.target.value) {
            setSearch(e.target.value);
            resetSuggestions();
            return;
        }

        if (e.target.value.trim()) {
            setSearch(e.target.value);
            getSuggestions(e.target.value);
        }
    }

    const selectSymptom = (clickIndex) => {
        if (selection < 0 || selection > suggestions.length - 1)
            return;

        if (tags.some(el => el.name === suggestions[selection].name))
            return;

        const index = (clickIndex === undefined)
            ? selection
            : clickIndex

        setTags([...tags, suggestions[index]]);
        setSuggestions([]);
        setSearch('');
    }

    const removeTag = (tag) => {
        if (tag < 0 || tag > tags.length - 1) return;
        if (disabled) return;

        var newTags = [...tags];
        newTags.splice(tag, 1);
        setTags(newTags);
    }

    const getHighlighted = (text, search) => {
        var texts = [];
        var i = -1;

        if (!search) return text;

        while ((i = text.toLowerCase().indexOf(search.toLowerCase())) !== -1) {
            texts.push(text.substring(0, i));
            texts.push(<span key={text} className='match'>{text.substring(i, i + search.length)}</span>);

            text = text.substring(i + search.length);
        }

        texts.push(text);
        return texts;
    }

    const resetSuggestions = () => {
        setSuggestions([]);
        setSelection(-1);
    }

    const resetSearch = () => {
        setSearch('');
        resetSuggestions();
    }

    const sentenceCase = (text) => {
        if (!text) return text;

        return text.slice(0, 1).toUpperCase() + text.slice(1).toLowerCase();
    }

    const getSuggestions = (searchFor) => {
        Object.assign(azureAPI.defaults, {
            headers: {
                "Content-Type": "application/json",
                "Correlation-Id": asyncHBID
            }
        });

        setLoadState('LOADING');

        searchPromise = azureAPI.post(
            "",
            {
                "query": searchFor,
                "sex": gender,
                "age_in_years": age,
                "max_results": 32
            }
        )

        const localPromise = searchPromise;
        // NOTE: This works for single instances only. 
        searchPromise.then(response => {
            if (searchPromise === localPromise) {
                setLoadState("DONE");
                const filteredSuggestions = response?.data?.data.filter(symptom => {
                    return (tags.find(tag => symptom.id === tag.id || !symptom.name) ? false : true);
                });

                setSuggestions(filteredSuggestions);
            }
        })
            .catch(function (error) {
                logEventHub('Failed request symptom text search.', 'FE - symptom search text');
                if (searchPromise === localPromise) {
                    setLoadState("DONE");
                    setSuggestions([]);
                }
            });
    };

    useEffect(() => {
        if (selection && keyWasPressed.current) {
            let el = document.querySelector('.suggestions .selected');
            if (el)
                el.scrollIntoView();
            keyWasPressed.current = '';
        }

        if (typeof setBodyPartHover !== 'function')
            setBodyPartHover = () => { };

        if (selection !== 0 && !selection) {
            setBodyPartHover("");
            return;
        }

        if (!suggestions || !suggestions.length) {
            setBodyPartHover("");
            return;
        }

        if (selection < 0 || selection > (suggestions.length - 1)) {
            setBodyPartHover("");
            return;
        }
    })

    useEffect(()=>{
        if(!isMobile){
            var input = document.getElementById('search-for-symptoms')
            input.focus();
        }
    }, [!isMobile]);

    return (
        <div className='symptom-text'>
            {
                isMobile ||
                <>
                    <label htmlFor='search-for-symptoms' >
                        <div className='header'>
                            <h2 className='headerText'>{getMarkDown(headerText)}</h2>
                        </div>
                        <div className='instructions'>{getMarkDown(instructions)}</div>
                    </label>
                </>
            }
            <div>
                <TextField
                    onFocus={() => { if (showToast instanceof Function) showToast(0) }}
                    size='small'
                    placeholder={placeholder}
                    variant="outlined"
                    id="search-for-symptoms"
                    aria-invalid="false"
                    className={inputDisabled ? 'disabled symptom-search textbox' : 'symptom-search textbox'}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    value={search}
                    disabled={inputDisabled}
                    autoComplete='off'
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchOutlinedIcon className='search' />
                            </InputAdornment>
                        )
                    }}
                />

                <div className='search-container'>
                    <div className='tag-box' style={(isMobile && search) ? { display: 'none' } : { display: 'block' }}>
                        {
                            tags.map((tag, i) => {
                                return (
                                    <button
                                        key={tag.name + inputDisabled}
                                        className='tag'
                                        onKeyDown={(e) => keyPressedOnTag(e, i)}
                                        disabled={disabled}
                                    >
                                        {sentenceCase(tag.name)}
                                        <div
                                            className={disabled ? 'disabled remove' : 'remove'}
                                            onClick={() => removeTag(i)}
                                        >
                                            <CloseIcon />
                                        </div>
                                    </button>)
                            })
                        }
                        {
                            (tags.length === 1) &&
                            <div
                                className='hint'
                                background={HoverColor + '14'}
                                disabled={disabled}
                            >
                                {addMore}
                            </div>
                        }
                        {/* {
                            (tags.length >= 6) &&
                            <ButtonTag
                                className='hint tag'
                                type='note'
                                background={HoverColor + '14'}
                            >
                                {onlySix}
                            </ButtonTag>
                        } */}
                    </div>

                    <div
                        className="click-catcher"
                        onClick={() => { resetSearch() }}
                        style={search ? { display: 'block' } : { display: 'none' }}
                    ></div>

                    <div className='suggestions' style={search ? { display: 'block' } : { display: 'none' }}>
                        <div>
                            {
                                (loadState === 'LOADING')
                                    ?
                                    <div className='loading'>
                                        {
                                            Array.from({ length: 3 }, (x, i) => {
                                                return (
                                                    <div
                                                        key={'waitbar' + i}
                                                        className='loadAnim'
                                                        style={{ opacity: 1 - (i * .3) }}
                                                    >
                                                        <div className='anim'>&nbsp;</div>
                                                    </div>)
                                            })
                                        }
                                    </div>
                                    :
                                    suggestions.length && loadState === 'DONE'
                                        ?
                                        suggestions.map((symptom, i) => {
                                            return (
                                                <div
                                                    key={symptom.name + i}
                                                    className={i === selection ? 'selected suggestion' : 'suggestion'}
                                                    onMouseEnter={() => setSelection(i)}
                                                    onClick={() => selectSymptom(i)}
                                                    title={symptom.name}
                                                >
                                                    {
                                                        getHighlighted(symptom.name, search)
                                                    }
                                                </div>)
                                        })
                                        :
                                        <div className='none'>
                                            {getMarkDown(noResults)}
                                            <span
                                                className='research'
                                                onClick={() => { resetSearch() }} >
                                                {getMarkDown(newSearch)}
                                            </span>
                                        </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default SymptomSearchMessage;