import React from "react";

const Checkmark = (props) => {
     return (
         <svg width="18px" height="14px" viewBox="0 0 18 14" style={{ marginRight : '10px' }} aria-labelledby={props.ariaLabelledby} role={props.role} >
             <title id={props.id+'-selected'}>Selected</title>
             <g id="•-Desktop/Tablet-Version-•" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                 <g id="Artboard" transform="translate(-481.000000, -72.000000)" fill="#FFFFFF" fillRule="nonzero">
                     <g id="buttons-copy" transform="translate(469.000000, 59.000000)">
                         <g id="done_white_24dp-copy-5" transform="translate(9.000000, 8.000000)">
                             <polygon id="Path" points="9 16.2 4.8 12 3.4 13.4 9 19 21 7 19.6 5.6"></polygon>
                         </g>
                     </g>
                 </g>
             </g>
         </svg>
     )
 }

 export default Checkmark;