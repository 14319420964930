import React, { useEffect, useState } from "react";
import { useDetectMobile, useDetectTablet } from './utils';
import TriageBot from "./TriageBotEngine";
import ProfileSelect from "./ProfileSelect";
import ProgressBar from "./ProgressBar";
import SummaryPrintable from "./print/SummaryPrintable";
import '../../stylesheets/triagebot/SympleNoteApp.scss';
import '../../stylesheets/triagebot/print/print.scss';
import axios from 'axios';

const keyVault = process.env.REACT_APP_KEY_VAULT;
const reconnectUrl = process.env.REACT_APP_RECONNECT_URL;
const trigger = process.env.REACT_APP_TRIGGER;
const filter = process.env.REACT_APP_FILTER_ID;
const searchTextURL = process.env.REACT_APP_SYMPTOM_TEXT_URL;
const searchBodyURL = process.env.REACT_APP_SYMPTOM_BODY_URL;
const profileURL = process.env.REACT_APP_PROFILES_URL;
const env = process.env.REACT_APP_ASYNC_ENVIRONMENT;
const cdoName = process.env.REACT_APP_CDO_NAME;
const appID = process.env.REACT_APP_APP_ID;
const customerApiUrl = process.env.REACT_APP_CUSTOMER_API_URL;
var DisplayLogoForMobileClient;
var DisplayLogoForDesktopClient;
var DisplayIconForDesktopClient;

if (env && (env.toLowerCase() === 'production')) {
  const noop = () => { };
  ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn',
  ].forEach((method) => {
    window.console[method] = noop;
  });
}

console.log("TRIGGER: ", trigger);
console.log("FILTER: ", filter);
console.log("TEXT SEARCH: ", searchTextURL);
console.log("BODY SEARCH: ", searchBodyURL);
console.log("PROFILES: ", profileURL);
console.log("KEYVAULT: ", keyVault);
console.log("RECONNECT: ", reconnectUrl);
console.log("ENVIRONMENT: ", env);
console.log("RELEASE: 4.0.2");
console.log("BUILD: 24.11.6");

function TriageBotPage() {
  const params = new URLSearchParams(window.location.search);
  const [botkey, setBotkey] = useState(Date.now());
  const [profile, setProfile] = useState();
  const [restart, setRestart] = useState(false);
  const [progressName, setProgressName] = useState('');
  const [configsAreLoaded, setConfigsAreLoaded] = useState(false);
  const [showWelcomeIllustration, setShowWelcomeIllustration] = useState(false);
  const [showLogo, setShowLogo] = useState(false);
  const [showToolBar, setShowToolBar] = useState(false);
  const [progressBar, setProgressBar] = useState(false);
  const [showDebugPanel, setShowDebugPanel] = useState(false);
  const [logo, setLogo] = useState('');
  const [hoverColor, setHoverColor] = useState('');
  const [printData, setPrintData] = useState(null);
  const [showIcon, setShowIcon] = useState(false);
  const [Icon, setIcon] = useState('');

  const { isMobile } = useDetectMobile();
  const { isTablet } = useDetectTablet();

  const headerHeight = (profileURL ? 50 : 0) + (showToolBar ? 80 : 0) - (isMobile ? 10 : 0)

  // **************************************
  // TODO: Move the config logic to it's own file
  // Configs stuff
  console.log('Triage Bot Page Started..')
  function toHex(c) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  function scaleInt(c, magnitude) {
    c = c + magnitude;
    c > 255 && (c = 255);
    c < 0 && (c = 1);

    return c
  }

  const brightenColor = (hexColor, magnitude) => {
    hexColor = hexColor.replace(`#`, ``);

    if (hexColor.length === 6) {

      let rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
      rgb = rgb ? {
        r: parseInt(rgb[1], 16),
        g: parseInt(rgb[2], 16),
        b: parseInt(rgb[3], 16)
      } : null;

      rgb.r = scaleInt(rgb.r, magnitude);
      rgb.g = scaleInt(rgb.g, magnitude);
      rgb.b = scaleInt(rgb.b, magnitude);

      return `#${toHex(rgb.r)}${toHex(rgb.g)}${toHex(rgb.b)}`;

    } else {
      return hexColor;
    }
  };

  const hexColorToRgba = (hexColor, alpha) => {
    hexColor = hexColor.replace(`#`, ``);

    if (hexColor.length === 6) {

      let rgb = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
      rgb = rgb ? {
        r: parseInt(rgb[1], 16),
        g: parseInt(rgb[2], 16),
        b: parseInt(rgb[3], 16)
      } : null;

      return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;

    } else {
      return hexColor;
    }
  };

  const loadConfigs = async () => {
    if (configsAreLoaded) return;

    console.log(`Loading cdo styles for ${cdoName}...`);

    try {
      const response = await axios({
        method: "get",
        url: `${customerApiUrl}?appId=${appID}`,
        timeout: 0,
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
      });
      setConfigsAreLoaded(true);
      updateConfigs(response.data);
    } catch (error) {
      return { error: error };
    }
  }

  const updateConfigs = (configs) => {
    const root = document.documentElement;

    setHoverColor(configs.buttonColor);

    console.log(configs);
    console.log('CDO Sytles retrieved:')
    console.log(`card-trim-color: ${configs.cardTrimColor}`)
    console.log(`font-family: ${configs.fontFamily}`)
    console.log(`body-text-color: ${configs.bodyTextColor}`)
    console.log(`button-color: ${configs.buttonColor}`)
    console.log(`header-options: ${configs.headerOption}`)
    console.log(`cdoName: ${configs.cdoName}`)
    DisplayLogoForMobileClient=configs.displayLogoForMobileClient;
    DisplayLogoForDesktopClient=configs.displayLogoForDesktopClient;
    DisplayIconForDesktopClient=configs.DisplayIconForDesktopClient;
    
    //card-trim-color
    if (configs.cardTrimColor)
      root.style.setProperty('--card-trim-color', configs.cardTrimColor);

    //font family
    if (configs.fontFamily)
      root.style.setProperty('--font-family', configs.fontFamily);
    if (configs.fontFamily)
      root.style.setProperty('--header-font-family', configs.fontFamily);
    if (configs.fontFamily)
      root.style.setProperty('--button-font-family', configs.fontFamily);

    //text-color
    if (configs.bodyTextColor)
      root.style.setProperty('--text-color', configs.bodyTextColor);
    if (configs.bodyTextColor)
      root.style.setProperty('--header-font-color', configs.bodyTextColor);

    //button Color
    if (configs.buttonColor) {
      root.style.setProperty('--button-active-color', configs.buttonColor);
      root.style.setProperty('--button-active-color2', brightenColor(configs.buttonColor, -12));
      root.style.setProperty('--provider-button-hover-color', hexColorToRgba(configs.buttonColor, .08));
    }
    if (configs.buttonColor)
      root.style.setProperty('--primary-color', configs.buttonColor);
    if (configs.buttonColor)
      root.style.setProperty('--button-active-border-color', configs.buttonColor);
    if (configs.buttonColor)
      root.style.setProperty('--button-hover-border-color', configs.buttonColor);
    if (configs.buttonColor)
      root.style.setProperty('--button-hover-background', configs.buttonColor);

    //displayWelcomeIllustration - true/false
    const welcomeIllustration = configs.displayWelcomeIllustration;
    setShowWelcomeIllustration(welcomeIllustration);

    //logo
    const IncomingLogo = configs.logo;
    setLogo(IncomingLogo);
    const IncomingIcon = configs.Icon
    setIcon(IncomingIcon)

    //headerOptions : can be none/both/logo/progressBar, 
    const options = configs.headerOption;
    if (options === 'both') {
      setShowToolBar(true);
      setShowLogo(true);
      setProgressBar(true);
      setShowIcon(true);
    }
    if (options === 'progressbar') {
      setShowToolBar(true);
      setProgressBar(true);
    }
    if (options === 'logo') {
      setShowToolBar(true);
      setShowLogo(true);
    }
    if (options === 'Icon') {
      setShowToolBar(true);
      setShowIcon(true);
    }
    const favIcon = configs.favIcon;

    var link = document.querySelector("link[rel~='icon']");
    if (favIcon) {
      link.href = `${favIcon}`;
    }
  }

  // ***** End of configs stuff
  // ********************************

  const profileChanged = (profile) => {
    setProfile(profile);
    setRestart(true);
  }

  const updateProgress = (name) => {
    setProgressName(name);
    console.log("CURRENT STEP: ", name);
  }

  const setSummaryPrintData = (data) => {
    if(data?.type?.toLowerCase() !== 'summary') return;

    setPrintData(data);
  }

  useEffect(() => {
    const getConfigs = async () => await loadConfigs();

    // If we don't have a profile drop down, we need to load the configs now.
    if (!profileURL)
      getConfigs().catch(console.error);

    // If we have the profile drop down, we'll load the configs after the profile loads
    if (restart) {
      getConfigs().catch(console.error);
      setBotkey(Date.now);
      setRestart(false);
    }
  })

  return (
    <div id='triage-bot-page' className='page'>
      {
        profileURL &&
        <div className='logobar'>

          {
            params.has('debug')
              ? <button className='button' onClick={() => setShowDebugPanel(true)}>Debug panel</button>
              : ''
          }

          <ProfileSelect key='profile-selector' classes='proselect' profileChanged={profileChanged} />
        </div>
      }

      {
        showToolBar &&
        <div className={isMobile ? 'mobile toolbar' : 'desktop toolbar'} >
          <div className='toolbar-container'>
            {
              isMobile ?
                DisplayLogoForMobileClient &&
                <div className='logo'>
                  {
                    logo && showLogo &&
                    <img className={isMobile ? 'mobile' : (isTablet ? 'tablet' : 'desktop')} src={`${logo}`} alt='Symple Note logo' />
                  }
                </div>
                : DisplayLogoForDesktopClient &&
                <div className='logo'>
                  {
                    logo && showLogo &&
                    <img className={isMobile ? 'mobile' : (isTablet ? 'tablet' : 'desktop')} src={`${logo}`} alt='Symple Note logo' />
                  }
                </div>
            }
              {
                !isMobile &&
                DisplayIconForDesktopClient &&
                <div className="Icon">
                  {
                    Icon && showIcon &&
                    <img className={(isTablet ? 'tablet' : 'desktop')} src={`${Icon}`} alt='Atrius Icon' />
                  }
                </div>
              }
            <div className='progress'>
              {
                progressBar &&
                <ProgressBar progressName={progressName} size={isMobile ? 130 : 150} />
              }
            </div>
          </div>
        </div>
      }

      <div id="page-body" className='page-body' style={{ top: headerHeight }}>
        {/* TODO: Can we change HoverColor from state variable to css variable (--provider-button-hover-color)? */}
        <TriageBot setSummaryPrintData={setSummaryPrintData} updateProgress={updateProgress} showDebug={showDebugPanel} setShowDebug={setShowDebugPanel} setRestart={setRestart} key={botkey} profile={profile} showWelcomeIllustration={showWelcomeIllustration} HoverColor={hoverColor} />
      </div>

      <div id='printable-body' className={params.has('showprint') ? 'showprint' : ''}>                
        <SummaryPrintable data={printData} logo={logo} key={new Date()} />
      </div>
    </div>
  );
}
export default TriageBotPage;
