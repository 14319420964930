import axios, { AxiosHeaders } from "axios";

// Get cookie from browser
function getCookie(cookiename) {
  let name = cookiename + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const axiosInstance = axios.create({
  withCredentials: true,
});

// Sets the Bearer token from a cookie and sends this token in the Auth header automatically
axiosInstance.interceptors.request.use((request) => {
  let MSFTToken = "";
  try {
    MSFTToken = getCookie("Bearer");
    if (MSFTToken) {
      (request.headers as AxiosHeaders).set('Authorization', `Bearer ${MSFTToken}`);
    }
  } catch (error) {
    console.error("No token was received");
  }

  return request;
});

export default axiosInstance;
