import React, { useEffect, useRef } from 'react';
import { getMarkDown } from '../utils';
import '../../../stylesheets/triagebot/print/page.scss';

const Page = ({ logo, footer, page, body }) => {
    const bodyRef = useRef(0);

    useEffect(() => {
        if (!Array.isArray(body)) return;

        body.forEach(element => {
            bodyRef.current.appendChild(element);
        })
    })

    return (
        <div className='page'>
            <div className="header">
                {
                    logo &&
                    <img className='logo' src={`${logo}`} alt='SympleNote logo' />
                }
            </div>
            <div ref={bodyRef}>

            </div>
            <div className="footer">
                {getMarkDown(footer.disclaimer)}
                <div className='date-page'>
                    <div className='date'>
                        {getMarkDown(footer.createdTxt)}
                    </div>
                    <div className='pager'>
                        {page}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Page