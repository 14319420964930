import React, { useState, } from 'react';
import Footer from './Footer';
import SymptomSearchText from './SymptomSearchText';
import SymptomSearchBody from './SymptomSearchBody';
import { getMarkDown, useDetectMobile } from '../utils';
import '../../../stylesheets/triagebot/SymptomSearch.scss';

const SymptomSearchMessage = ({ message, sendMessage, disabled, setToastMessage, HoverColor }) => {
    const [submitted, setSubmitted] = useState(false);
    const [editing, setEditing] = useState(false);
    const [symptomsList, setSymptomsList] = useState([]);
    const [bodyPartHover, setBodyPartHover] = useState("");

    const { isMobile } = useDetectMobile();

    const headerText = message?.data?.[0]?.headerText
        ? message.data[0].headerText
        : '';

    const instructions = (message?.text)
        ? message.text
        : '';

    const searchTab = message?.data?.[0]?.searchHeaderText
        ? message.data[0].searchHeaderText
        : '';

    const bodyTab = message?.data?.[0]?.bodyHeaderText
        ? message.data[0].bodyHeaderText
        : '';

    const questionId = (message?.data?.[0]?.question_id)
        ? message.data[0].question_id
        : ''

    const isDisabled = submitted || disabled;

    const isSubmittionDisabled = submitted || !symptomsList.length || isDisabled;

    const addSymptomTag = (value) => {
        setSymptomsList(value);
        setToastMessage(null);
    };

    const submitPress = (event) => {
        if (!symptomsList.length) return;

        if (submitted) {
            sendMessage('rewind', questionId, questionId);
            setSubmitted(false);
            setEditing(true);
            return;
        }

        setSubmitted(true);

        var retSymptoms = []

        symptomsList.forEach(symptom => {
            retSymptoms.push({
                name: symptom.name,
                id: symptom.id
            })
        })

        editing
            ? sendMessage('message', `##edit@${message.data[0].question_id}@${JSON.stringify(retSymptoms)}`, `##edit@${message.data[0].question_id}@${retSymptoms}`)
            : sendMessage('message', JSON.stringify(retSymptoms), retSymptoms);
    };

    const showToast = (tab) => {
        setToastMessage(
            {
                type: 'symptoms',
                fullscreen: true,
                header: '',
                body: (
                    <div className='symptom-search-toast'>
                        <div className='tabbar'>
                            <div
                                className={tab ? 'tab' : 'selected tab'}
                                onClick={() => showToast(0)}
                            >
                                {searchTab}
                            </div>
                            <div
                                className={tab ? 'selected tab' : 'tab'}
                                onClick={() => showToast(1)}
                            >
                                {bodyTab}
                            </div>
                            <button 
                                id='iphone-focus-hack'
                                onClick={()=>{ document.querySelector('.symptom-search-toast input')?.focus(); console.log('clicked!') }}
                                style={{ visibility: 'hidden' }}
                            ></button>
                        </div>
                        {
                            tab
                                ? <SymptomSearchBody message={message} tags={symptomsList} setTags={addSymptomTag} bodyPartHover={bodyPartHover} setBodyPartHover={setBodyPartHover} disabled={isDisabled} HoverColor={HoverColor} />
                                : <SymptomSearchText message={message} tags={symptomsList} setTags={addSymptomTag} setBodyPartHover={setBodyPartHover} disabled={isDisabled} HoverColor={HoverColor} />
                        }
                    </div>
                ),
                // Overrides the default focus
                focus: () => { document.querySelector('#iphone-focus-hack')?.click();  }
            }
        );
    }

    return (
        <div className={
            (isDisabled ? 'disabled ' : '') +
            (isMobile ? 'mobile ' : 'desktop ') +
            'symptom-search card'}
        >
            <div className={'content'}>
                <div
                    className='input-blocker'
                    style={submitted ? { display: "block" } : { display: "none" }}
                />
                {
                    isMobile &&
                    <>
                        <div className='header'>{getMarkDown(headerText)}</div>
                        <div className='instructions'>{getMarkDown(instructions)}</div>
                    </>
                }
                {
                    isMobile
                        ?
                        <div>
                            <div className='tabbar'>
                                <div className={'selected tab'}                               >
                                    {searchTab}
                                </div>
                                <div
                                    className={'tab'}
                                    onClick={() => showToast(1)}
                                >
                                    {bodyTab}
                                </div>
                            </div>
                            <SymptomSearchText message={message} showToast={() => showToast(0)} tags={symptomsList} setTags={addSymptomTag} setBodyPartHover={setBodyPartHover} disabled={isDisabled} HoverColor={HoverColor} />
                        </div>

                        : // is Desktop
                        <div>
                            <SymptomSearchText message={message} tags={symptomsList} setTags={setSymptomsList} setBodyPartHover={setBodyPartHover} disabled={isDisabled} HoverColor={HoverColor} />
                            <SymptomSearchBody message={message} tags={symptomsList} setTags={setSymptomsList} bodyPartHover={bodyPartHover} setBodyPartHover={setBodyPartHover} disabled={isDisabled} HoverColor={HoverColor} />
                        </div>
                }
            </div>
            <Footer
                showChangeButton={questionId && submitted && !message.isLastMessage}
                onAccepted={submitPress}
                onDenied={() => sendMessage('fastforward')}
                disabled={disabled}
                divID={questionId}
            >
                    <button
                        className='submit-button'
                        onClick={submitPress}
                        disabled={isSubmittionDisabled}
                        style={{'visibility': submitted ? 'hidden': '', 'display': submitted ? 'none' : ''}}
                        aria-hidden={isSubmittionDisabled ? 'true': 'false'}
                    >
                        Next
                    </button>                
            </Footer>
        </div>
    );
};

export default SymptomSearchMessage;