import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Checkmark from '../../../assets/checkmark.js';
import { getMarkDown } from '../utils';
import '../../../stylesheets/triagebot/SingleNext.scss';
import { useDetectMobile } from '../utils';

//************************************* */

const SingleNextMessage = ({ message, sendMessage, disabled }) => {

  const currentPcp = message?.data?.[1]?.currentPcp || '';

  const [selected, setSelected] = useState({ id: currentPcp.id, name: currentPcp.name });
  const [submitted, setSubmitted] = useState(false);
  const [editing, setEditing] = useState(false);

  const questionId = message?.data?.[0]?.question_id;

  const isDisabled = submitted || disabled;

  const Buttons = message?.buttons;

  const headerText = message?.data?.[0]?.headerText;
  const subHeader = message?.data?.[0]?.subHeaderText;

  const { isMobile } = useDetectMobile();

  const choicePress = (choose) => {
    if (submitted) return;

    var obj = {
      id: choose.value,
      name: choose.displayText
    }

    setSelected(obj);
  };

  const submitPress = (event) => {
    if (submitted) {
      sendMessage('rewind', questionId, questionId);
      setSubmitted(false);
      setEditing(true);
      return;
    }

    var submitValue = selected.id;

    setSubmitted(true);

    editing
      ? sendMessage('message', `##edit@${message.data[0].question_id}@${submitValue || 'none'}`, `##edit@${message.data[0].question_id}@${submitValue || 'none'}`)
      : sendMessage('message', submitValue || 'none', submitValue || 'none');
  };

  useEffect(() => {
    var header = document.querySelector('h2');
    setTimeout(() => {
      header.focus();
    }, 500);
  }, []);

  return (
    <div className={isDisabled ? 'disabled multi-select card' : 'multi-select card'}>
      <div className='content' >
        <div className='header' id={questionId}>
          <h2 className='headerText'> {getMarkDown(headerText)} </h2>
          <div className='subheader'> {getMarkDown(subHeader)} </div>
        </div>

        <div className='body' aria-labelledby={questionId} role='group'>
          {Buttons.map((choice, key) => {
            const isSelected = selected.id===choice.value;
            return (
              <button
                key={key}
                className={(isMobile ? 'mobile ' : 'desktop ') + (isSelected ? 'multi-button selected' : 'multi-button')}
                onClick={() => choicePress(choice)}
                onMouseDown={(e) => e.preventDefault()}
                disabled={submitted || disabled}
              >
                {
                  (isSelected) &&
                  <Checkmark
                    className='checkmark'
                    role="img"
                    ariaLabelledby={choice.displayText + (isSelected ? "-selected" : "")}
                    id={choice.displayText}
                  />
                }
                <label htmlFor={choice.displayText.toLowerCase()}>{choice.displayText}</label>
              </button>
            );
          })}
        </div>
      </div>

      <Footer
        showChangeButton={questionId && submitted && !message.isLastMessage}
        onAccepted={submitPress}
        onDenied={() => sendMessage('fastforward')}
        disabled={disabled}
        divID={questionId}
      >
        <button
          onClick={submitPress}
          className='submit-button'
          disabled={isDisabled}
          style={{ visibility: submitted ? 'hidden' : 'visible', 'display': submitted ? 'none' : '' }}
          aria-hidden={submitted ? 'true' : 'false'}
        >
          Next
          </button>
      </Footer>

    </div>
  );
};

export default SingleNextMessage;
