import React from 'react';
import '../../../stylesheets/triagebot/PossibleCausesLink.scss';
import { ReactComponent as YesIcon } from '../../../assets/check.svg';
import { ReactComponent as NoIcon } from '../../../assets/close-icon.svg';
import { useDetectMobile } from '../utils';

const PossibleCausesLink = ({message}) => {

    const subHeader =  message.text ? message.text : '';
    const forReason =  message.reasonsForHeader ? message.reasonsForHeader : '';
    const againstReason = message.reasonsAgainstHeader ? message.reasonsAgainstHeader : '';
    const positiveListResponse  = message.data.supporting_evidence ? message.data.supporting_evidence : [];
    const negativeListResponse  = message.data.conflicting_evidence ? message.data.conflicting_evidence : [];

    const {isMobile}  = useDetectMobile();

    return (
        <div className='cause-body'>
            <div className='cause-subHeader'>{subHeader}</div>

            <div className='Reasons' style={isMobile ? {display : "block"} : {display : 'flex'}}>
                <div className='Positive' style={isMobile ? {marginBottom: "40px"} : {width: "50%"}}>
                    <div className='ReasonTitle'>
                        <h3 className='headerText1'>{forReason+":"}</h3>
                    </div>
                    <div className='cause-labels'>
                        {   
                            positiveListResponse
                            ?
                                positiveListResponse.map((PlistRes)=>{
                                    return (
                                        <div key={PlistRes} style={{marginBottom : "8px"}}>
                                            <YesIcon className="yes icon"/>
                                            <ul>
                                                <div>
                                                    <span>{PlistRes}</span>
                                                </div>
                                            </ul>
                                        </div>
                                    )
                                })
                            : null
                        }
                    </div>
                </div>
                <div className='Negative' style={isMobile ? {marginBottom: "10px"} : {width: "50%"}}>
                    <div className='ReasonTitle'>
                        <h3 className='headerText1'>{againstReason+":"}</h3>
                    </div>
                    <div className='cause-labels'>
                        {   
                            negativeListResponse 
                            ?
                                negativeListResponse.map((negaListresp)=>{
                                    return(
                                        <div key={negaListresp} style={{marginBottom : "8px"}}>
                                            <NoIcon className="no icon"/>
                                            <ul>
                                                <div>
                                                    <span>{negaListresp}</span>
                                                </div>
                                            </ul>
                                        </div>
                                    )
                                })
                            : null
                        }                          
                    </div>
                </div>
            </div>

        </div>
    )
}

export default PossibleCausesLink;
