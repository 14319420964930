import React from 'react';
import { getMarkDown } from '../utils';
import { ReactComponent as PhoneIcon } from '../../../assets/icon-phone.svg';
import '../../../stylesheets/triagebot/Errors.scss';
import '../../../stylesheets/triagebot/Emergency.scss';

const Nine11Message = ({ message }) => {
    var headerText = (message.data && message.data.length && message.data[0].headerText)
        ? message.data[0].headerText
        : ''

    var alarming_symptoms = (message.data && (message.data.length > 1) && message.data[1].alarmingSymptoms)
        ? message.data[1].alarmingSymptoms
        : []

    var concerningSymptomsHeader = (message.data && (message.data.length > 1) && message.data[1].concerningSymptomsHeader)
        ? message.data[1].concerningSymptomsHeader
        : ''

    return (
        <div className='nine11 warning card'>
            <div className='content' role='region' aria-live='polite'>
                <div className='portion icon'>
                    <PhoneIcon />
                </div>
                <div>
                    <div>
                        <div className='header'>
                            <h2 className='headerText'>{getMarkDown(headerText)}</h2>
                        </div>
                        <div className='body'>
                            {getMarkDown(message.text)}
                        </div>
                    </div>

                    {(alarming_symptoms.length > 0) &&
                        <div>
                            <div className='symptomHeader'>
                                {concerningSymptomsHeader}
                            </div>
                            <ul className='symptom'>
                                {
                                    alarming_symptoms.map((symptom, index) => {
                                        return (
                                            <li key={`concerning-symptom${index}`} > { symptom}</li>
                                        )
                                    })
                                }
                            </ul>
                        </div>}
                </div>
            </div>
        </div >
    );
}

export default Nine11Message;