
import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import Checkmark from '../../../assets/checkmark.js';
import { getMarkDown } from '../utils';
import '../../../stylesheets/triagebot/MultiSelect.scss';
import { useDetectMobile } from '../utils';

//************************************* */

const MultiSelectMessage = ({ message, sendMessage, disabled }) => {
  const [choices, setChoices] = useState([...getChoices()]);
  const [submitted, setSubmitted] = useState(false);
  const [editing, setEditing] = useState(false);

  const showCheckMark = choices.length && (choices[choices.length - 1].value || '').toLowerCase() !== 'none';
  const hasNoneOption = choices.length ? choices[choices.length - 1].value.toLowerCase() === 'none' : false;
  const hasSelection = choices.filter(choice => choice.selected).length;

  const questionId = (message && message.data && message.data.length && message.data[0] && message.data[0].question_id)
    ? message.data[0].question_id
    : '';

  const isDisabled = submitted || disabled;

  const { isMobile } = useDetectMobile();

  const subHeaderText = (message?.data?.[0]?.subHeaderText)
    ? message.data[0].subHeaderText
    : '';

  function getChoices() {
    // Are choices located at data[1]?
    if (message && (message.data.length > 1) && message.data[1].length && message.data[1][0] && message.data[1][0].displayText)
      return message.data[1];

    // Are the choices located at data[0]?
    if (message && message.data.length && message.data[0].item) {
      return message.data[0].forEach((item) => {
        message.choices.push({
          displayText: item,
          title: item,
          value: item,
        });
      });
    }

    return [];
  }

  const unselectNone = () => {
    setChoices(
      [...choices.map(choice => {
        if (choice.value.toLowerCase() === 'none')
          choice.selected = false;
        return choice;
      })]
    )
  };

  const choicePress = (choice) => {
    if (submitted) return;

    const noneSelected = choice.value.toLowerCase() === 'none';

    choice.selected = noneSelected
      ? true
      : !choice.selected;

    choices.forEach(choice => {
      if (noneSelected) {
        if (choice.value.toLowerCase() !== 'none')
          choice.selected = false;
      }
      else {
        if (choice.value.toLowerCase() === 'none')
          choice.selected = false;
      }
    });

    setChoices([...choices]);

    if (noneSelected)
      submitPress();

  };

  const submitPress = (event) => {
    if (submitted) {
      unselectNone();
      sendMessage('rewind', questionId, questionId);
      setSubmitted(false);
      setEditing(true);
      return;
    }

    var submitValue = '';

    choices.forEach(choice => {
      if (choice.selected) { submitValue.length === 0 ? submitValue += choice.value : (submitValue += ',' + choice.value); };
    });

    setSubmitted(true);

    editing
      ? sendMessage('message', `##edit@${message.data[0].question_id}@${submitValue || 'none'}`, `##edit@${message.data[0].question_id}@${submitValue || 'none'}`)
      : sendMessage('message', submitValue || 'none', submitValue || 'none');
  };

  useEffect(() => {
    var header = document.getElementById('button-multi-select');
    setTimeout(() => {
      header.focus();
    }, 500);
  }, []);

  return (
    <div className={isDisabled ? 'disabled multi-select card' : 'multi-select card'}>
      <div className='content' >
        <div className='header' id={questionId}>
          <h2 className='headerText'> {getMarkDown(message)} </h2>
          {
            subHeaderText &&
            <div className='subheader'>
              {getMarkDown(subHeaderText)}
            </div>
          }
        </div>
        <div className='body' aria-labelledby={questionId} role='group'>
          {choices.map((choice, key) => {
            return (
              <button
                id='button-multi-select'
                key={key}
                className={(isMobile ? 'mobile ' : 'desktop ') + (choice.selected ? 'multi-button selected' : 'multi-button')}
                onClick={() => choicePress(choice)}
                onMouseDown={(e) => e.preventDefault()}
                disabled={submitted || disabled}
              >
                {
                  (showCheckMark && choice.selected) &&
                  <Checkmark
                    className='checkmark'
                    role="img"
                    ariaLabelledby={choice.displayText + (choice.selected ? "-selected" : "")}
                    id={choice.displayText}
                  />
                }
                <label htmlFor={choice.displayText.toLowerCase()}>{choice.displayText}</label>
              </button>
            );
          })}
        </div>
      </div>

      <Footer
        showChangeButton={questionId && submitted && !message.isLastMessage}
        onAccepted={submitPress}
        onDenied={() => sendMessage('fastforward')}
        disabled={disabled}
        divID={questionId}
      >
        <button
          onClick={submitPress}
          className='submit-button'
          disabled={isDisabled || (hasNoneOption && !hasSelection)}
          style={{ visibility: submitted ? 'hidden' : 'visible', 'display': submitted ? 'none' : '' }}
          aria-hidden={submitted ? 'true' : 'false'}
        >
          Next
          </button>
      </Footer>

    </div>
  );
};

export default MultiSelectMessage;
