import React, { useState, useEffect, useCallback } from 'react';
import Footer from './Footer';
import { getMarkDown } from '../utils';
import '../../../stylesheets/triagebot/Welcome.scss';

const ConglomoSelfCareMessage = ({ message, sendMessage, disabled }) => {

    const [submitted, setSubmitted] = useState(false);
    const headerText = (message.data && message.data.length && message.data[0].headerText)
        ? message.data[0].headerText || message.data[0].header
        : ''

        const isDisabled = submitted || disabled;

        const submitPress = (event, button) => {
            if (submitted) return;
    
            setSubmitted(true);
    
            sendMessage('message', 'Ok', 'Ok');
        }

    return (
        <div className={isDisabled?'disabled welcome card':'welcome card'}>
            <div className='content' >
                
                <div>
                    <h1 className='header'> {headerText} </h1>
                </div>
                <div className='body'>
                    {getMarkDown(message.text)}
                </div>
            </div>
            <Footer>
                        <button
                            key={123214}
                            className='submit-button'
                            onClick={submitPress}
                            disabled={isDisabled}
                        >
                            Next
                        </button>
            </Footer>
        </div>
    );
}

export default ConglomoSelfCareMessage;