import React, { useEffect, useState } from 'react';
import { getMarkDown } from '../utils';
import Footer from './Footer';
import TextField from '@mui/material/TextField';
import '../../../stylesheets/triagebot/FreeTextField.scss';

const FreeTextField = ({ message, sendMessage, disabled }) => {
    const [input, setInput] = useState("");
    const [characterCount, setCharacterCount] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const MAX_CHAR = 500;

    const inputChanged = (e) => {
        setCharacterCount(e.target.value.length)
        setInput(e.target.value);
    }

    const isDisabled = submitted || disabled;

    const submitPress = (event, button) => {
        if (submitted) return;

        setSubmitted(true);
        if (input === "")
            sendMessage('message', "N/A", "N/A")
        else
            sendMessage('message', input, input)
    }

    const headerText = (message.data && message.data.length && message.data[0].headerText)
        ? message.data[0].headerText
        : ''

    const subHeaderText = (message.data && message.data.length && message.data[0].subHeaderText)
        ? message.data[0].subHeaderText
        : ''
    
    useEffect(()=>{
        var textfield = document.getElementById('anything-else');
        setTimeout(() => {
            textfield.focus();
        }, 500); 
    }, []);


    return (
        <div className={isDisabled?'disabled freetextfield card':'freetextfield card'}>
            <div className='content'>
                <label htmlFor='anything-else'>
                    <div className='header'>
                        <h2 className='headerText' tabIndex={isDisabled?"-1":"0"}>{getMarkDown(headerText)}</h2> 
                    </div>
                    <div className='subHeader'>
                        {getMarkDown(subHeaderText)}
                    </div>
                </label>
                <div className='body'>
                    <TextField
                        className='comment'
                        variant="outlined"
                        id="anything-else" aria-invalid="false"
                        multiline
                        minRows={4}
                        maxRows={4}
                        value={input}
                        onChange={inputChanged}
                        inputProps={{ maxLength: 500, style: { 'marginBottom': 15 } }}
                        disabled={isDisabled}
                    />
                </div>
                <div className={(characterCount) ? 'counter' : 'ghosted counter'} >
                    {MAX_CHAR - characterCount}
                </div>
            </div >
            <Footer>
                {
                    submitted ||
                    <button
                        className={'submit-button'}
                        onClick={submitPress}
                        disabled={disabled}
                    >
                        Next
                        </button>
                }
            </Footer>

        </div >
    );
}

export default FreeTextField;