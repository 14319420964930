import React from 'react';
import { getMarkDown } from '../utils';
import { ReactComponent as InfoIcon } from '../../../assets/info-circle.svg';
import '../../../stylesheets/triagebot/InfoButton.scss';

const InfoButton = ({ type, onShowInfo, header, text, explication, disabled }) => {
    type = type || 'info';
    text = text || '';

    const lastIndex = text.lastIndexOf(" ");
    const firstWords = text.substring(0, lastIndex);
    const lastWord = text.substring(lastIndex);

    if (typeof onShowInfo !== 'function')
        onShowInfo = () => { };

    const showInfoDialog = () => {
        onShowInfo({
            type: type,
            header: header,
            body: explication
        })
    }

    return (
        <span className='info-text'>
            {
                getMarkDown(firstWords + '&#32;')
            }

            <span className='nowrap'>
                {
                    getMarkDown(lastWord)
                }
                {
                    <button
                        className={type}
                        disabled={disabled}
                        onClick={showInfoDialog}
                    >
                        <InfoIcon />
                    </button>
                }
            </span>
        </span>
    )
}

export default InfoButton;