import React, { useState, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2'
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Footer from '../cards/Footer';
import { getMarkDown, useDetectMobile } from '../utils';
import 'react-phone-input-2/lib/style.css';
import '../../../stylesheets/triagebot/PhoneNumberDialog.scss';
import '../../../stylesheets/triagebot/Button.scss';
import styled from 'styled-components';
//************************//

const Body = styled.div`
    padding: 24px 0px;
`

const Buttons = styled.button`
        font-weight: 400;
        font-size: 16px;
        font-family: var(--button-font-family);
        background-color: #FFFFFF;
        cursor: pointer;
        border-radius: 6px;
        padding: 10px 24px;
        color: var(--button-active-color);
        border: solid 1px var(--button-active-border-color);
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        user-select: none;
        text-align: left;
        margin: 8px 0px 8px 4px;

        &:disabled{
            cursor: not-allowed;
            opacity: 0.5;
        }
    
        &.selected {
            color: #FFFFFF;
            border: solid 1px var(--button-hover-border-color);
            background-color: var(--button-hover-background);
        }
    
        &:focus:enabled,
        &.selected:focus{
            transition: outline 0.25s;
            border: solid 1px var(--button-active-border-color);
            outline: 4px solid rgba(0, 95, 190, 0.3);
        }
    
        &:focus:not(:focus-visible),
        &.selected:focus:not(:focus-visible) {
            outline: none;
            transition: none;
        }
    
        &:hover:enabled{
            color: #FFFFFF;
            border: solid 1px var(--button-hover-border-color);
            background-color: var(--button-hover-background);
        }
    
        // Button selected and hover
        &.selected:hover:enabled {
            background-color: var(--button-active-color2);
            border: solid 1px var(--button-active-color2);
        }

        &:first-child {
            margin-top: 0px;
        }
        &:last-child {
            margin-bottom: 0px;
        }
`

//*********************** */

const PhoneNumberDialog = ({ message, sendMessage, setSubmitted, clearParentSelection, open, setOpen, onClose, value, disabled, HoverColor }) => {
    const [contactMethod, setContactMethod] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [addNew, setAddNew] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [canContact, setCanContact] = useState('');
    const [card, setCard] = useState(0);

    const { isMobile } = useDetectMobile();

    if (disabled) open = false;

    if (!(typeof setSubmitted === 'function'))
        setSubmitted = () => { };

    const data = (message && message.data)
        ? message.data
        : message

    const cardHeaders = (data.headerTexts)
        ? data.headerTexts
        : ['', '', '', '']

    const contactMethods = (data.contactMethods)
        ? data.contactMethods
        : ['', '']

    const phoneNumbers = (data.phoneNumbers)
        ? data.phoneNumbers
        : []

    const addNewText = (data.addNewText)
        ? data.addNewText
        : ''

    const yesNoButtons = (data.yesNoButtons)
        ? data.yesNoButtons
        : []

    const CANCEL = (data.cancelText)
        ? data.cancelText
        : ''

    const BACK = (data.backText)
        ? data.backText
        : ''

    const NEXT = (data.nextText)
        ? data.nextText
        : ''

    const SUBMIT = (data.submitText)
        ? data.submitText
        : ''

    const clearFocus = () => {
        setTimeout(() => {
            document.activeElement.blur();
        }, 0);
    }

    const editPhone = (value) => {
        const number = value.charAt(0) === '1' ? value : ('1' + value);
        setPhoneNumber(number);
        setIsValid(number.length === 11);
    }

    const resetState = () => {
        setContactMethod('');
        setPhoneNumber('');
        setIsValid(false);
        setAddNew(false);
        setCanContact('');
        setCard(0);
    }

    const closeDialog = () => {
        resetState();
        setOpen(false);
    }

    const submitMessage = () => {
        let messageText
            = value
            + ", "
            + phoneNumber
            + ", "
            + (canContact === yesNoButtons[0])

        sendMessage('message', messageText, messageText);
        setSubmitted(true);
        closeDialog();
    }

    const handleCancelClick = () => {
        document.activeElement.blur();

        switch (card) {
            case 0:
                clearParentSelection();
                closeDialog();
                return;

            case 1:
                setPhoneNumber('');
                setAddNew(false);
                return setCard(0);

            case 2:
                let prevCard = phoneNumbers.length
                    ? 1
                    : 0

                setIsValid(false);
                setPhoneNumber('');
                return setCard(prevCard);

            case 3:
                setCanContact('');

                return (addNew || !phoneNumbers.length)
                    ? setCard(2)
                    : setCard(1)

            default:
                return;
        }
    }

    const handleSubmitClick = () => {
        document.activeElement.blur();

        switch (card) {
            case 0:
                let nextCard = phoneNumbers.length
                    ? 1
                    : 2

                return (contactMethod === contactMethods[0])
                    ? submitMessage()
                    : setCard(nextCard)

            case 1:
                return phoneNumber
                    ? setCard(3)
                    : setCard(addNew ? 2 : 1)

            case 2:
                return setCard(isValid ? 3 : 2)

            case 3:
                return submitMessage();

            default:
                return;
        }
    }

    const isDisabled = () => {
        switch (card) {
            case 0:
                return !contactMethod || disabled;

            case 1:
                return (!phoneNumber && !addNew) || disabled;

            case 2:
                return !isValid || disabled;

            case 3:
                return !canContact || disabled;

            default:
                return;
        }
    }

    function getStyle(oElm, strCssRule) {
        var strValue = "";
        if (document.defaultView && document.defaultView.getComputedStyle) {
            strValue = document.defaultView.getComputedStyle(oElm, "").getPropertyValue(strCssRule);
        }
        else if (oElm.currentStyle) {
            strCssRule = strCssRule.replace(/-(\w)/g, function (strMatch, p1) {
                return p1.toUpperCase();
            });
            strValue = oElm.currentStyle[strCssRule];
        }
        return strValue;
    }

    useEffect(() => {
        setTimeout(() => {
            const cards = document.getElementsByClassName('turn');

            if (!cards.length) return;

            const cWidth = cards[cards.length - 1].offsetWidth;
            const dialog = document.querySelector('#phone-card-dialog .MuiDialog-paper');

            if (!dialog) return;

            const padding = parseInt(getStyle(dialog, 'padding-left')) + parseInt(getStyle(dialog, 'padding-right'));

            dialog.style.width = (cWidth - padding) + 'px';
            dialog.style.minWidth = (cWidth - padding) + 'px';
        }, 1)
    })

    const cardContents = [
        // First Card
        <Body className='card0 body'>
            {
                contactMethods.map((method, index) => {
                    return (
                        <Buttons
                            key={'contactMethod' + index}
                            className={method === contactMethod ? 'selected multi-button' : 'multi-button'}
                            onClick={() => {
                                setContactMethod(method);
                                clearFocus();
                            }}
                            disabled={disabled}
                        >
                            { method}
                        </Buttons>
                    )
                })
            }
        </Body>,

        // Second Card
        <Body className='card1 body'>
            {
                phoneNumbers.map((number, index) => {
                    return (
                        <Buttons
                            key={'pn' + index}
                            className={number === phoneNumber ? 'selected multi-button' : 'multi-button'}
                            onClick={() => {
                                setAddNew(false);
                                setPhoneNumber(number);
                                clearFocus();
                            }}
                            disabled={disabled}
                        >
                            { number}
                        </Buttons>
                    )
                })
            }
            <Buttons
                className={addNew ? 'selected multi-button' : 'multi-button'}
                onClick={() => {
                    setAddNew(true);
                    setPhoneNumber('');
                    clearFocus();
                }}
                disabled={disabled}
            >
                { addNewText }
            </Buttons>
        </Body>,

        // Third Card
        <Body className='card2 body'>
            <PhoneInput
                country='us'
                onlyCountries={['us']}
                value={phoneNumber}
                containerClass='phone-container'
                inputClass='phone-input'
                buttonClass='phone-button'
                onChange={value => editPhone(value)}
                disableDropdown={true}
                disableCountryCode={false}
                disabled={disabled}
            />
        </Body>,

        // Forth Card
        <Body className='card3 body'>
            {
                yesNoButtons.map((option, index) => {
                    return (
                        <Buttons
                            key={'YesOrNo' + index}
                            className={option === canContact ? 'selected multi-button' : 'multi-button'}
                            onClick={() => { 
                                setCanContact(option);
                                clearFocus();
                            }}
                            disabled={disabled}
                        >
                            { option}
                        </Buttons>
                    )
                })
            }
        </Body>

    ]

    return (
        <Dialog
            id='phone-card-dialog'
            open={open}
            onClose={onClose}
        >
            {
                <div>
                    <div id="dialog-title">
                        <h2 className='headerText'>{getMarkDown(cardHeaders[card] || '')}</h2>
                    </div>

                    <DialogContent id="dialog-body">
                        {cardContents[card]}
                    </DialogContent>

                    <DialogActions id="dialog-footer">
                        <Footer>
                            <button
                                className={isMobile ? 'mobile cancel-button' : 'cancel-button'}
                                onClick={handleCancelClick}
                            >
                                {card === 0 ? CANCEL : BACK}
                            </button>

                            <button
                                className={'continue-button'}
                                onClick={() => handleSubmitClick()}
                                disabled={isDisabled()}
                            >
                                {card === (cardContents.length - 1) ? SUBMIT : NEXT}
                            </button>
                        </Footer>
                    </DialogActions>
                </div>
            }
        </Dialog>
    )
}

export default PhoneNumberDialog;