import React, { useState,useEffect } from 'react';
import Footer from './Footer';
import { getMarkDown, useDetectMobile } from '../utils';
import '../../../stylesheets/triagebot/SymptomDuration.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';



const SymptomDurationCard = ({ message, sendMessage, disabled, setToastMessage, HoverColor }) => {
    const [submitted, setSubmitted] = useState(false);
    const [editing, setEditing] = useState(false);
    const { isMobile } = useDetectMobile();
    const [durationData, setDurationData] = useState([]);
    const placeholder='0'

    
    
    var symValidation = [];

    const headerText = message?.data?.[0]?.headerText
        ? message.data[0].headerText
        : '';
    
    const subHeaderText = message?.data?.[0]?.subHeaderText
        ? message.data[0].subHeaderText
        : '';

    const durationUnits = message?.data?.[1]?.durationUnits
        ? message.data[1].durationUnits
        : '';

    const tableHeaders = message?.data?.[1]?.tableHeaders
        ? message.data[1].tableHeaders
        : '';
    
    const symptoms = message?.data?.[1]?.symptoms
        ? message.data[1].symptoms
        : '';
    
    const questionId = (message?.data?.[0]?.question_id)
        ? message.data[0].question_id
        : ''

    useEffect(() => {
        const defaultSympArray = symptoms.map((symptom, index) => ({ name: symptom.name, id: symptom.id, duration: { unit: 'select', value: '0' } }))
        setDurationData(defaultSympArray);
    },[]);

    const submitPress = (event) => {
        if (submitted) {
            sendMessage('rewind', questionId, questionId);
            setSubmitted(false);
            setEditing(true);
            return;
        }

        setSubmitted(true);
        
        editing
            ? sendMessage('message', `##edit@${message.data[0].question_id}@${JSON.stringify(durationData)}`, `##edit@${message.data[0].question_id}@${durationData}`)
            : sendMessage('message', JSON.stringify(durationData), durationData);
    };
    
    const regex = /[^\d+$]/;
    const updateFieldChanged = index => e => {
        var SympArray = [...durationData];
        if(e.target.name==='unit')
        SympArray[index].duration.unit = e.target.value;
        if (e.target.name === 'duration') 
        SympArray[index].duration.value = e.target.value;
        setDurationData(SympArray);
    }
    
    durationData.forEach(symptom => {(symptom.duration.unit==='select' || regex.test(symptom.duration.value) || symptom.duration.value==='0' || symptom.duration.value==='' || (parseInt(symptom.duration.value)??0) < 0 )?symValidation.push(false):symValidation.push(true)});
    const isDisabled = submitted || disabled;

    const isSubmittionDisabled = submitted || (symValidation.includes(false)) || isDisabled;

        return (
        <div className={
            (isDisabled ? 'disabled ' : '') +
            (isMobile ? 'mobile ' : 'desktop ') +
            'symptom-duration card'}
        >
            <div className={'content'}>
                <div
                    className='input-blocker'
                    style={submitted ? { display: "block" } : { display: "none" }}
                />
                {
                    //isMobile &&
                    <>
                        <div className='header'>{getMarkDown(headerText)}</div>
                        <div className='subheader'>{getMarkDown(subHeaderText)}</div>
                    </>
                }
                {
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 500 }} aria-label="simple table">
        <colgroup>
        <col style={{width:'50%'}}/>
        <col style={{width:'20%'}}/>
        <col style={{width:'30%'}}/>
        </colgroup>
        <TableHead>
        <TableRow>
        {
            tableHeaders.map((header, hindex) => 
            <TableCell key={hindex}> {getMarkDown(header)}</TableCell>
        )}
          </TableRow>
        </TableHead>

        <TableBody>
          {
            symptoms.map((row, rindex) =>(
            <TableRow
            key={'lbltr'+row.name+rindex}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
        <TableCell>
        <TextField
                    id="symptom-questions" 
                    placeholder={placeholder}
                    size='small'
                    aria-invalid="false"
                    variant="outlined"
                    onChange={updateFieldChanged(rindex)}
                    className={disabled ? 'disabled symptom-question-search textbox' :
                    'symptom-question-search textbox'}
                    disabled={disabled}
                    InputProps={{
                        inputProps: { min: 0}
                    }}
                    type="number"
                    name="duration"
                    />
              </TableCell>
        <TableCell> 
        <FormControl sx={{ m: 1, minWidth: 150 }}>
        <InputLabel id="demo-simple-select-autowidth-label">Units</InputLabel>
              <Select 
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        name="unit"
        label="Units"
        defaultValue={""}
        onChange={updateFieldChanged(rindex)} 
        >
        <MenuItem value="select">Select Unit</MenuItem>
            {
                durationUnits.map((durUnit, dindex) => {
                    return (
                        
                        <MenuItem key={durUnit.value + dindex} value={durUnit.value}
                        className="menu-item"
                            >
                            
                            {durUnit.displayText}
                            </MenuItem>
                    )}
                
            )}
                </Select>
            </FormControl>
        </TableCell>
    </TableRow>
          ))}
    </TableBody>
    </Table>
    </TableContainer>
    }
    </div>
            <Footer
                showChangeButton={questionId && submitted && !message.isLastMessage}
                onAccepted={submitPress}
                onDenied={() => sendMessage('fastforward')}
                disabled={disabled}
                divID={questionId}
            >
                    <button
                        className='submit-button'
                        onClick={submitPress}
                        disabled={isSubmittionDisabled}
                        style={{'visibility': submitted ? 'hidden': '', 'display': submitted ? 'none' : ''}}
                        aria-hidden={8 ? 'true': 'false'}
                    >
                        Next
                    </button>                
            </Footer>
        
        </div>
    );
    
}

export default SymptomDurationCard;