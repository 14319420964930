import React from 'react';
import { getMarkDown } from '../utils';
import { ReactComponent as HospitalIcon } from '../../../assets/Icon-ER+building.svg';
import '../../../stylesheets/triagebot/Errors.scss';
import '../../../stylesheets/triagebot/Emergency.scss';

const EmergencyMessage = ({ message }) => {
    var headerText = (message.data && message.data.length && message.data[0].headerText)
        ? message.data[0].headerText
        : ''
    var concerning_symptoms = message.data[1].alarmingSymptoms;

    return (
        <div className='emergency warning card' >
            <div className='content' role='region' aria-live='polite' >
                <div className='portion icon'>
                    <HospitalIcon />
                </div>
                <div>
                    <div>
                        <div className='header'>
                            <h2 className='headerText'>{getMarkDown(headerText)}</h2>
                        </div>
                        <div className='body'>
                            {getMarkDown(message.text)}
                        </div>
                    </div>

                    {concerning_symptoms.length > 0 &&
                        message.data[1].concerningSymptomsHeader && 
                        <div>
                            <div className='symptomHeader'>
                                {message.data[1].concerningSymptomsHeader}
                            </div>
                            <ul className='symptom'>
                                {
                                    concerning_symptoms.map((symptom, index) => {
                                        return (
                                            <li key={`concerning-symptom${index}`} > {symptom}</li>
                                        )
                                    })
                                }
                            </ul>
                        </div>}
                </div>
            </div>
        </div>
    );
}

export default EmergencyMessage;