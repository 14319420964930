import React from 'react';
import { getMarkDown } from '../utils';
import '../../../stylesheets/triagebot/Text.scss';

const Text = ({ message, sendMessage, }) => {
  const bubbleType = { message: 'text', text: 'text', prompt: 'text', warning: 'flag', calendar: 'text', doctorCard: 'text' }
  const bubbleClass = bubbleType[message.type] + ' message' 

  return (<div className={ bubbleClass }>
    { getMarkDown(message) }
  </div>);
};

export default Text;
