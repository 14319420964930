import React, { useEffect, useState, useRef } from 'react';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import '../../../stylesheets/triagebot/Dialog.scss';

const DebugDialog = ({ showDebug, reconnectDirectline, sendMessage, directLine, onClose }) => {
    const [message, setMessage] = useState();
    const [count, setCount] = useState(0);

    onClose = (typeof onClose === 'function')
        ? onClose
        : () => { }

    let tokenDiv = useRef();
    let streamDiv = useRef();

    const reconnect = async () => {
        await reconnectDirectline();
        setCount(count+1);
    }

    useEffect(() => {
        tokenDiv.current = directLine?.token;
    }, [directLine])

    return (
        <Dialog
            id='debug-dialog'
            open={showDebug}
            onClose={onClose}
        >

            <DialogContent>
                <div className='option'>
                    <label>TOKEN: </label>
                    <div className='breaking text' ref={tokenDiv}>{directLine.token}</div>
                </div>

                <div className='option'>
                    <label>STREAM: </label>
                    <div className='breaking text' ref={streamDiv}>{directLine.streamUrl}</div>
                </div>

                <div className='option'>
                    <input style={{ visibility: 'hidden' }} />
                    <button
                        onClick={reconnect}>
                        reconnect to DirectLine<br />
                    </button>
                </div>

                <div className='option'>
                    <input
                        value={message}
                        onChange={() => setMessage(message)}
                    />
                    <button
                        onClick={() => sendMessage('message', message, message)}>
                        Send Message
                </button>
                </div>
            </DialogContent>

        </Dialog>
    )
};

export default DebugDialog;