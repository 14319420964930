export const oopsCard =
{
   "type": "oops",
   "text": "**Oops**  \nSomething went wrong on our end. We apologize for the inconvenience, please click \"restart\" to start again.",
   "id": 1
}

export const timeoutCard =
{
   "type": "timeout",
   "text": "**Timeout Error**  \nWe apologize for the inconvenience. Please click \"Restart\".",
   "id": 1
}

export const connectErrCard =
{
   "type": "connecterr",
   "text": "**Connection Error**  \nWe're having trouble connecting. Please click \"Restart\".",
   "id": 1
}