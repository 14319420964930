import React, { useState } from 'react';
import PromptMessage from './SingleSelectCard';
import PhoneNumberDialog from '../dialogs/PhoneNumberDialog';
import '../../../stylesheets/triagebot/Errors.scss';

const TreatmentPlanMessage = ({ message, sendMessage, disabled, HoverColor }) => {
    const [actionText, setActionText] = useState('');
    const [openPhoneNumberDialog, setOpenPhoneNumberDialog] = useState(false);
    const [submitted, setSubmitted] = useState();
    const [buttonValue, setButtonValue] = useState('');

    const isDisabled = submitted || disabled;

    const hasSlots = (message?.data?.[0]?.hasSlots)
        ? message.data[0].hasSlots
        : false;

    const careOptions = (message?.data?.[1]?.careOptionsData?.careOptions)
        ? message.data[1].careOptionsData.careOptions
        : []

    const promptText = (message?.data?.[1]?.careOptionsData?.sectionHeader)
        ? message.data[1].careOptionsData.sectionHeader
        : ''

    const promptMessage = {
        type: 'prompt',
        text: promptText,
        buttons: careOptions.filter(option=>(hasSlots || option.value.toLowerCase()!=='virtualcare'))
    };

    let phoneCardMessage = {
        type: "phoneNumberCard",
        text: "",
        data: []
    };

    if (message?.data?.length > 2)
        phoneCardMessage = message.data[2]

    const sendPrompt = (message, text, value) => {
        setActionText(text);

        if (text.toLowerCase() === 'contactme') {
            setButtonValue(text);
            setOpenPhoneNumberDialog(true)
            return;
        }

        sendMessage('message', text || 'none', text || 'none');
        setSubmitted(true);
    }

    const clearPhone = () => {
        setActionText('');
    }

    return (
        <>
            <PromptMessage
                message={promptMessage}
                sendMessage={sendPrompt}
                keepAlive={true}
                sentMessage={submitted}
                selection={actionText}
                disabled={isDisabled}
            />
            <PhoneNumberDialog
                open={openPhoneNumberDialog}
                setOpen={setOpenPhoneNumberDialog}
                message={phoneCardMessage}
                sendMessage={sendMessage}
                clearParentSelection={clearPhone}
                setSubmitted={setSubmitted}
                value={buttonValue}
                disabled={isDisabled}
                HoverColor={HoverColor}
            />
        </>
    );
}

export default TreatmentPlanMessage;