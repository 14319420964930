import React, { useState } from 'react';
import Zippy from './Zippy';
import Footer from './Footer';
import Checkbox from '@mui/material/Checkbox';
import { ReactComponent as Done } from '../../../assets/done.svg';
import { getMarkDown, useDetectMobile } from '../utils';
import '../../../stylesheets/triagebot/ConfirmVirtual.scss';

const ConfirmVirtualMessage = ({ message, sendMessage, disabled }) => {
    const steps = (message.data && message.data.length && message.data[0].steps && Array.isArray(message.data[0].steps))
        ? message.data[0].steps
        : []

    const [currentStep, setCurrentStep] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [openZippy, setOpenZippy] = useState(0);
    const [isChecked, setIsChecked] = useState(Array(steps.length).fill(false));

    if (!(sendMessage instanceof Function))
        sendMessage = () => { };

    const { isMobile } = useDetectMobile();

    const isDisabled = disabled || submitted;

    const isValid = isChecked.every(s => s);

    const headerText = (message.data && message.data.length)
        ? message.data[0].headerText || message.data[0].header
        : ''

    const subHeaderText = (message.data && message.data.length)
        ? message.data[0].subHeaderText
        : ''

    const checkboxClick = (index) => {
        isChecked[index] = !isChecked[index];
        setIsChecked([...isChecked]);

        if (isChecked[index])
            setOpenZippy(index + 1);

        if (index >= currentStep)
            setCurrentStep(index + 1);
    }

    const toggleCheckbox = (event, index) => {
        if (event.key.toLowerCase() === 'enter'){
            isChecked[index] = !isChecked[index];
            setIsChecked([...isChecked]);

            if (isChecked[index])
            setOpenZippy(index + 1);

            if (index >= currentStep)
                setCurrentStep(index + 1);
        }
    }

    const getTitle = (step, i) => {
        return (
            <span className='title'>
                <h3 className='headerText'><span className={(i > currentStep) ? 'disabled' : ''}>{step.title}</span></h3>
                {
                    isChecked[i]
                        ? <Done />
                        : <span>{step.step}</span>
                }
            </span>
        )
    }

    const toggleZippy = (i) => {
        openZippy === i
            ? setOpenZippy(-1)
            : setOpenZippy(i)
    }

    const cancelPress = () => {
        sendMessage('rewind', ['summaryPlaceHolder', 'emergency'], ['summaryPlaceHolder', 'emergency']);
        sendMessage('message', 'returnerr', 'returnerr');
    }


    const submitPress = (event, button) => {
        if (submitted) return;

        setSubmitted(true);

        sendMessage('message', 'Next', 'Next');
    }

    return (

        <div className={(isDisabled ? 'disabled ' : '') + (isMobile ? 'mobile ' : '') + 'confirm-virtual card'} >
            <div className='content'>
                <div className='header'>
                    <h2 className='headerText'>{getMarkDown(headerText)}</h2>
                    <div className='subheader'>
                        {getMarkDown(subHeaderText)}
                    </div>
                </div>
                <div className='body'>
                    {
                        steps.map((step, i) => {
                            return (
                                <Zippy
                                    title={getTitle(step, i)}
                                    open={openZippy === i}
                                    onClick={() => toggleZippy(i)}
                                    disabled={(i > currentStep) || isDisabled}
                                    key={'step' + i}
                                >
                                    <div className='step-container'>
                                        {getMarkDown(step.text)}

                                        <div className={isDisabled ? 'disabled checkbox-container' : 'checkbox-container'}>
                                            <Checkbox
                                                type="checkbox"
                                                id={"cv-checkbox" + i}
                                                className='checkbox'
                                                name="agreement"
                                                checked={isChecked[i]}
                                                onChange={() => checkboxClick(i)}
                                                disabled={isDisabled}
                                                onKeyPress={(e)=>toggleCheckbox(e, i)}
                                            />
                                            <label className='check-label' htmlFor={"cv-checkbox" + i}>{
                                                getMarkDown(step.checkbox)}
                                            </label>
                                        </div>
                                    </div>
                                </Zippy>
                            )
                        })
                    }
                </div>
            </div>
            {submitted ||
                <Footer>
                    <button
                        onClick={cancelPress}
                        className={isMobile ? 'mobile cancel-button' : 'cancel-button'}
                        disabled={isDisabled}
                    >
                        Cancel
                        </button>
                    <button
                        onClick={submitPress}
                        className='continue-button'
                        disabled={!isValid || isDisabled}
                    >
                        Next
                        </button>
                </Footer>
            }
        </div>
    );
}

export default ConfirmVirtualMessage;