import React from 'react';
import { getMarkDown } from '../utils';

const TextCard = ({ message }) => {

    const header = (message.data && message.data.length)
        ? message.data[0].header || message.data[0].headerText
        : ''

    return (
        <div className='message card'>
            <div className='content' role='region' aria-live='polite' tabIndex={-1}>
                <div className='header' style={{ marginTop: 0}}>
                    {getMarkDown(header)}
                </div>
                <div className='body'>
                    {getMarkDown(message)}
                </div>
            </div>
        </div>
    );
}

export default TextCard;