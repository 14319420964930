import React, { useEffect } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Footer from '../cards/Footer';
import { getMarkDown, useDetectMobile } from '../utils';
import '../../../stylesheets/triagebot/Dialog.scss';

const ChangeDialog = ({ isDialogOpen, onClose, isAccepted, disabled }) => {
    if (disabled) isDialogOpen = false;

    const {isMobile} = useDetectMobile();

    onClose = (typeof onClose === 'function')
        ? onClose
        : () => { }

    function getStyle(oElm, strCssRule) {
        var strValue = "";
        if (document.defaultView && document.defaultView.getComputedStyle) {
            strValue = document.defaultView.getComputedStyle(oElm, "").getPropertyValue(strCssRule);
        }
        else if (oElm.currentStyle) {
            strCssRule = strCssRule.replace(/-(\w)/g, function (strMatch, p1) {
                return p1.toUpperCase();
            });
            strValue = oElm.currentStyle[strCssRule];
        }
        return strValue;
    }

    useEffect(() => {
        setTimeout(() => {
            const cards = document.getElementsByClassName('turn');

            if (!cards.length) return;

            const cWidth = cards[cards.length - 1].offsetWidth;
            const dialog = document.querySelector('#change-dialog .MuiDialog-paper');

            if (!dialog) return;

            const padding = parseInt(getStyle(dialog, 'padding-left')) + parseInt(getStyle(dialog, 'padding-right'));

            dialog.style.width = (cWidth - padding) + 'px';
            dialog.style.minWidth = (cWidth - padding) + 'px';
        }, 1)
    })

    const handleButtonEffect = ()=>{ 
        isAccepted(false);

        //document.activeElement.blur();
        setTimeout(() => {
            document.activeElement.blur();
        }, 0);
    }

    return (
        <Dialog
            id='change-dialog'
            open={isDialogOpen}
           onClose={onClose}
           onBackdropClick={onClose}     
        >
            <div id="dialog-title">
                <h2 className='headerText'>{getMarkDown('**Do you want to change your answer?**')}</h2>
            </div>

            <DialogContent dividers id="dialog-body">
                {getMarkDown('This will delete any following responses and restart you from this question.')}
            </DialogContent>
            
            <DialogActions id="dialog-footer">
                <Footer>
                    <button
                        className={isMobile ? 'mobile cancel-button' : 'cancel-button'}
                        onClick={handleButtonEffect}
                    >
                        Cancel
                    </button>
                    <button
                        className='continue-button'
                        onClick={()=>isAccepted(true)}
                    >
                        Continue
                    </button>
                </Footer>
            </DialogActions>
        </Dialog>
    )
};

export default ChangeDialog;