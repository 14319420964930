import React, { useState, useEffect } from 'react';
import { getMarkDown } from '../utils';
import '../../../stylesheets/triagebot/Range.scss';
import Footer from './Footer'

const RangeMessage = ({ message, sendMessage, npsValue, setNpsValue, disabled }) => {
    const [windowWidth, setWindowWidth] = useState(0);

    const isSmallMobile = (windowWidth <= 370);
    const isMobile = (windowWidth > 370 && windowWidth <= 820);

    const buttons = [];

    const RANGE = { min: 0, max: 10, step: 1, minText: '', maxText: '' }

    const range = (message && message.data && message.data.length && message.data[0])
        ? { ...RANGE, ...message.data[0] }
        : RANGE

    range.min = parseInt(range.min) || 0;

    range.max = parseInt(range.max) || 0;

    range.step = parseInt(range.step) || 1;

    const isDisabled = disabled;

    const updateWindowWidth = () => {
        setWindowWidth(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth)
    }

    sendMessage = (typeof sendMessage === 'function')
        ? sendMessage
        : () => { }

    useEffect(() => {
        window.addEventListener('resize', updateWindowWidth);
        updateWindowWidth();
        return () => window.removeEventListener('resize', updateWindowWidth);
    }, [])

    for (let i = range.min; i <= range.max; i += range.step)
        buttons.push(
            <button
                key={'value' + i}
                onClick={() => handleButtonClick(i)}
                className={i === npsValue ? "selected tick" : "tick"}
                disabled={isDisabled}
                id='button-range'
            >
                {i}
            </button>);
    
    const handleButtonClick = (selection) => {
        setNpsValue(selection);
        sendMessage('message', selection, selection);
    }

    const buttonPress = () => {
        sendMessage('message', npsValue, npsValue);
    }

    return (
        <div className={isDisabled ? 'submitted rangecard' : 'rangecard'} id='NpsRating'>
            <div className='header' style= {{ color : `var(--primary-color)` }} id='nps-range-card'>
                <h2 className='headerText' >{getMarkDown(message)}</h2>
            </div>
            {
                isMobile
                    ?
                    <div className={'mobile range'}>
                        <div className='mobile buttons'>
                            {
                                buttons.map((button, i) => {
                                    if (i < 5) return button
                                })
                            }
                        </div>
                        <div className='mobile buttons'>
                            {
                                buttons.map((button, i) => {
                                    if (i > 4) return button
                                }
                                )
                            }
                        </div>
                        <div className="mobile label">From 0 (not likely) to 10 (very likely)</div>
                    </div>
                    :
                    isSmallMobile
                    ?
                        <div className={'mobile range'}>
                            <div className='mobile buttons'>
                                {
                                    buttons.map((button, i) => {
                                        if (i < 3) return button
                                    })
                                }
                            </div>
                            <div className='mobile buttons'>
                                {
                                    buttons.map((button, i) => {
                                        if (i > 2 && i<7) return button
                                    })
                                }
                            </div>
                            <div className='mobile buttons'>
                                {
                                    buttons.map((button, i) => {
                                        if (i > 6) return button
                                    })
                                } 
                            </div>
                            <div className="mobile label">From 0 (not likely) to 10 (very likely)</div>
                        </div>
                        :
                        <div className='range' >
                            <div className='buttons' role='group' aria-labelledby='nps-range-card'>
                                {buttons}
                            </div>
                            <div className='labels'>
                                <div className="min label">{range.minText}</div>
                                <div className="max label">{range.maxText}</div>
                            </div>
                        </div>
            }
            <Footer>
                <button
                    className='submit-button'
                    onClick={() => buttonPress()}
                >
                    Next
                </button>
            </Footer>
        </div >);
};

export default RangeMessage;
