import React, { useState,useEffect } from "react";
import { getMarkDown } from '../utils';
import { ReactComponent as ZippyArrow } from '../../../assets/zippyarrow.svg';
import '../../../stylesheets/triagebot/TimePicker.scss';
import styled from 'styled-components';
import Zippy from "./Zippy";
 
//****************************************** */
 
const Buttons = styled.button`
    padding: 10px 20px;
    margin: 0;
    margin-bottom:10px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    text-align: left;
    height: 40px;
    font-weight: 400;
    font-size: 16px;
    font-family: var(--button-font-family);
    background-color: white;
    cursor: pointer;
    border-radius: 6px;
    color: var(--button-active-color);
    border: solid 1px var(--button-active-border-color);
    width: calc(100% - 5px);
    white-space: nowrap;
 
    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
 
    &:hover:enabled {
        background-color: var(--button-active-color);
        color: #FFFFFF;
        border: solid 1px var(--button-active-border-color);
    }
 
    &.selected{
        background-color: var(--button-active-color);
        color: #FFFFFF;
        max-width: 125px;
        min-width: 125px;
    }
 
    &.selected:hover:enabled{
        background-color: var(--button-active-color2);
        border: 1px solid var(--button-active-color2);
    }
 
    &:focus:enabled,
    &.selected:focus{
        transition: outline 0.25s;
        border: solid 1px var(--button-active-border-color);
        outline: 4px solid rgba(0, 124, 186, 0.3);
    }
 
    &:focus:not(:focus-visible),
    &.selected:focus:not(:focus-visible) {
        outline: none;
        transition: none;
    }
   
  `
 
 
//****************************************** */
 
 
const TimePicker = ({ displayTimes, selectedTime, setSelectedTime, showAll, setShowAll, isMobile, disabled, providerInfo, showDept, aemMeridiem,slotFormat,currentDate, setProviderName, ProviderName , providerLocation = "", cdoname = ""}) => {
 
    displayTimes = displayTimes || [];
 
    if (!(setSelectedTime instanceof Function))
        setSelectedTime = () => { };

    // const columns = isMobile ? 2 : 4;

    const shouldShowAll = showAll || !isMobile;
 
    const displaySlots = displayTimes.slice(0, shouldShowAll ? 999 : 12);

    const SubgroupFrozen = "SubgroupFrozen";
    const providerNames = [...new Set(providerInfo.map((e) => e['providerName']))]; //Getting Unique Providers
    const toggleSelectedTime = (time, provider = '') => {
        (time === selectedTime && ProviderName === provider)
            ? setSelectedTime('')
            : setSelectedTime(time)
        setProviderName(provider)
        
        setTimeout(() => { document.activeElement.blur() }, 0);
    }

    useEffect(() => {
        setSelectedTime('')
    },[showDept])
 
    useEffect(() => {
        const MultiApptElements = document.querySelectorAll('.contentMultiAppointment')
        if (MultiApptElements && MultiApptElements?.length) {
            const element = MultiApptElements[MultiApptElements?.length-1]
            element.scrollIntoView(
                {
                    block: 'center',
                    behavior: 'smooth',
                    inline: 'center'
                })
        }
    }, [])

    var uniqueDisplaySlots=[]
    displaySlots.map((time) => {
        if(!uniqueDisplaySlots.includes(JSON.stringify(time))){
            uniqueDisplaySlots.push(JSON.stringify(time))
        }
    });
    //To sort the timeslots in AM to PM order
    uniqueDisplaySlots = uniqueDisplaySlots.map((time)=> new Date(time.replace(/"/g, ""))).sort((a,b) => a - b);
    const [openedTimeZippy, setOpenedTimeZippy] = useState(-1);
    const toggleZippies = (zippy) => {
       setOpenedTimeZippy(zippy)
    }
 
    const getTimeString = (date) => {
        let hour = date.getUTCHours();
        let minute = date.getUTCMinutes();
        const meridiem = hour > 11 ? aemMeridiem.PM : aemMeridiem.AM;
        hour = ((hour + 11) % 12 + 1)
        minute = (minute < 10) ? '0' + minute : minute;
 
        return `${hour}:${minute} ${meridiem}`;
    }
 
    const updateShowAll = (value) => {
        setShowAll(value);
        setShowAll(value);
    }
 
    const getTitle = (prov, providerLocation1 , providerLocation2 ) => {
        return (
            <span>
                    {prov}
                {providerLocation1 && <span className='provider-Location'>
                    <b>{providerLocation1}</b>
                </span>}
                {providerLocation2 && <span className='provider-Location'>
                    {providerLocation2}
                </span>}
            </span>
        )
    }  

    return (
        <div className='time-panel'>
            {
                isMobile &&
                <div className='count'>
                    {getMarkDown(`${displayTimes.length} appointments available`)}
                </div>
            }
            {
                (showDept || slotFormat===SubgroupFrozen) ? (
                    <div className="times timeSlotbuttonContainer timesDept">
                        {
                            providerNames.map((prov, i) => {
                                const provdata = providerInfo.filter(
                                    (pinfo) => String(pinfo.providerName) === String(prov)
                                );
                                // remove duplicate appointmentTime
                                let providerData = Array.from(new Set(provdata.map(JSON.stringify))).map(JSON.parse);
                                var provDates=[];
                                var myset = new Set();
                                providerData.forEach((pdata) => {
                                    myset.add(new Date(pdata.appointmentTime).getUTCDate())
                                })
                                const provAddress = (cdoname==="Atrius")? provdata?.[0]?.providerAddress?.split("~"):"";
                                const provAddressLine1=provAddress?.[0]? provAddress[0]:'';
                                const provAddressLine2=(provAddress?.[1]? provAddress[1]:'')+' '+(provAddress?.[2]? provAddress[2]:'')+' '+(provAddress?.[3]? provAddress[3]:'')
                                provDates=[...myset]
                                return (
                                 provDates.includes(currentDate) && 
                                 <Zippy
                                        title={provAddress?getTitle(prov, provAddressLine1 , provAddressLine2):getTitle(prov)}
                                        open={(!(openedTimeZippy === i) && providerData.length >= 1 && !disabled)}
                                        onClick={() => { toggleZippies(openedTimeZippy === i ? -1 : i); setShowAll(false); }}
                                        disabled={disabled}
                                        key={i}
                                    >
                                        <div className='providers' key={i}>
                                            <div className="slotButtonGrid">
                                                {
                                                    uniqueDisplaySlots.map((time, i) => {
                                                        const selected = time.getTime() === selectedTime && prov === ProviderName? 'selected' : 'timeSlotbutton';
                                                        return providerData.map((pdata) => {
                                                            return (
                                                                pdata.appointmentTime === time.getTime() && (
 
                                                                    <Buttons
                                                                        key={time.getTime()}
                                                                        className={selected}
                                                                        onClick={() => toggleSelectedTime(time.getTime(), prov)}
                                                                        disabled={disabled}
                                                                    >
                                                                        {getTimeString(time)}
                                                                    </Buttons>
 
                                                                )
                                                            );
                                                        });
                                                    })}
                                            </div>
                                            {/* {[
                                                ...Array(
                                                    columns - (displaySlots.length % columns || columns)
                                                ),
                                            ].map((slot, i) => {
                                                return (
                                                    <Buttons
                                                        key={'spacer' + i}
                                                        className='single-select hidden'
                                                    >
                                                        aemMeridiem.AM
                                                    </Buttons>
                                                );
                                            })} */}
                                        </div>
                                    </Zippy>
                                );
                            })
                        }
                    </div>
                ) : (
                    <div className="times timeSlotbuttonContainer">
                        {
                            uniqueDisplaySlots.map((time) => {
                                const selected = time.getTime() === selectedTime ? 'selected' : 'timeSlotbutton';
                                return (
                                    <Buttons
                                        key={time.getTime()}
                                        className={selected}
                                        onClick={() => toggleSelectedTime(time.getTime())}
                                        disabled={disabled}>
                                        {getTimeString(time)}
                                    </Buttons>
                                );
                            })
                        }
                        {/* {[...Array(columns - (displaySlots.length % columns || columns))].map(
                            (slot, i) => {
                                return (
                                    <Buttons key={'spacer' + i} className='single-select hidden'>
                                        aemMeridiem.AM
                                    </Buttons>
                                );
                            }
                        )} */}
                    </div>
                )
            }
            {
                (isMobile && (displayTimes.length > 12)) &&
                <div className='pagination'>
                    {
                        showAll
                            ? <button
                                onClick={() => updateShowAll(false)}
                                className='show less'
                                disabled={disabled}
                            >
                                View Less
                                <ZippyArrow />
                            </button>
                            : <button
                                onClick={() => updateShowAll(true)}
                                className='show more'
                                disabled={disabled}
                            >
                                Load More
                                <ZippyArrow />
                            </button>
                    }
                </div>
            }
        </div >
    );
}
 
export default TimePicker;