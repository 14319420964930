import React, { useState, useEffect, useCallback } from 'react';
import Footer from './Footer';
import Checkbox from '@mui/material/Checkbox';
import ProviderToastBody from '../dialogs/ProviderToastBody';
import { getMarkDown } from '../utils';
import { ReactComponent as WelcomeImage } from '../../../assets/SympleNote_illustration.svg';
import '../../../stylesheets/triagebot/Welcome.scss';

const WelcomeMessage = ({ message, sendMessage, setToastMessage, disabled, showWelcomeIllustration }) => {
    const checkboxEnv = process.env.REACT_APP_WELCOME_CHECKBOX || 'true';
    const showCheckbox = checkboxEnv.toLowerCase().trim() === 'true' ? true : false;
    const [submitted, setSubmitted] = useState(false);
    const [isChecked, setIsChecked] = useState(!showCheckbox);

    const isDisabledRef = React.useRef();
    const isDisabled = submitted || disabled;
    isDisabledRef.current = isDisabled;

    const isValid = isChecked;

    const headerText = (message.data && message.data.length && message.data[0].headerText)
        ? message.data[0].headerText || message.data[0].header
        : ''

    const checkboxText = (message.data && message.data.length && message.data[0].checkBoxText)
        ? message.data[0].checkBoxText
        : ''

    const dialogData = (message && message.data && (message.data.length > 1))
        ? message.data[1]
        : ''

    const providers = (dialogData && Array.isArray(dialogData.data))
        ? dialogData.data.filter(provider => provider.name && provider.name.trim())
        : []

    const pcps = providers.filter(provider => provider && provider.providerName && provider.providerName.toLowerCase().endsWith('pcp'));

    const [selectedProvider, setSelectedProvider] = useState(pcps[0] || providers[0] || { id: 'Next', name: 'No providers available' });

    const isPCP = (provider) => {
        return provider && provider.providerName && provider.providerName.toLowerCase().endsWith('pcp');
    }

    const buttonPress = (event, button) => {
        if (isDisabled) return;

        setSubmitted(true);

        sendMessage('message', selectedProvider.id || -1, selectedProvider.id || -1);
    }

    const toggleCheckbox = (event) => {
        if (event.key.toLowerCase() === 'enter')
            setIsChecked(!isChecked);
    }

    const agreementToggleHandler = (event) => {
        if (submitted) {
            event.preventDefault();
            return;
        };

        setIsChecked(event.target.checked)
    }

    const insertDrName = (text, showSpecility) => {
        if (typeof text !== 'string') return text;

        if (typeof selectedProvider.name !== 'string') return text;

        const name = selectedProvider.name || '';
        let spec = selectedProvider.speciality || '';
        spec = (spec ? ', ' : '') + spec.trim();

        return showSpecility
            ? text.replace('[-]', `**${name.trim()}**${(isPCP(selectedProvider) ? ', your primary care provider' : spec)}.`)
            : text.replace('[-]', `**${name.trim()}**`)
    }

    const handleProviderSelected = (selection) => {
        setSelectedProvider(selection);
        setToastMessage(null);
    }

    const showProviderToast = (e, provider) => {
        e.preventDefault();
        if (isDisabledRef.current) return false;

        setToastMessage(
            {
                type: dialogData.type,
                header: dialogData.headerText,
                body: (
                    <ProviderToastBody
                        message={dialogData}
                        onProviderSelected={handleProviderSelected}
                        selectedProvider={provider}
                    />)
            }
        );

        return false;
    }

    useEffect(() => {
        const button = document.querySelector('.-anchor') || document.querySelector('.anchor');

        if (!button) return;

        button.onclick = (e) => showProviderToast(e, selectedProvider);
        (providers.length < 2)
            ? button.className = 'anchor hidden'
            : button.className = 'anchor'

        return (button) => {
            return () => button.onclick = {};
        }

    }, [selectedProvider])

    return (
        <div className={isDisabled ? 'disabled welcome card' : 'welcome card'}>
            <div className='content' >
                {showWelcomeIllustration ?
                    <div className='logo'>
                        <WelcomeImage />
                    </div>
                    : <></>}
                <div>
                    <h1 className='header'> {headerText} </h1>
                </div>
                <div className='body'>
                    {getMarkDown(insertDrName(message.text, true))}
                </div>
                <div className="agreement-container">
                    {showCheckbox &&
                        <Checkbox
                            type="checkbox"
                            id="agreement"
                            className='agreement'
                            name="agreement"
                            onKeyPress={toggleCheckbox}
                            checked={isChecked}
                            onChange={agreementToggleHandler}
                            disabled={isDisabled}
                        />
                    }
                    <div></div>
                    <label htmlFor="agreement">{getMarkDown(insertDrName(checkboxText))}</label>
                </div>
            </div>
            <Footer>
                {message.buttons.map((button, key) => {
                    return (
                        <button
                            key={key}
                            className='submit-button'
                            onClick={(event) => buttonPress(event, button)}
                            disabled={!isValid || isDisabled}
                        >
                            { button.displayText}
                        </button>
                    );
                })}
            </Footer>
        </div>
    );
}

export default WelcomeMessage;