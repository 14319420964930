import React from 'react';
import { useDetectMobile, getMarkDown } from '../utils';
import '../../../stylesheets/triagebot/ProviderInfo.scss';
import { ReactComponent as RadioDot } from '../../../assets/Radio_dot.svg';
import { ReactComponent as Radiofill } from '../../../assets/Radio_fill.svg';

const ProviderToast = ({ message, onProviderSelected, selectedProvider }) => {
    onProviderSelected = (onProviderSelected instanceof Function)
        ? onProviderSelected
        : () => { }

    const providers = Array.isArray(message.data)
        ? message.data.filter(provider => provider.name)
        : []

    const {isMobile} = useDetectMobile();

    const pcpText = (message && message.pcpText)
        ? message.pcpText
        : ''

    const handleProviderSelect = (provider) => {
        document.getElementById('toast').style.top = '100%';
        setTimeout(()=>{
            onProviderSelected(provider);
            document.activeElement.blur();
        }, 500);
    }

    return (
        <div style={{ width: "100%" }} className='providers dialog' id='provider-dialog' role="group" aria-labelledby="which-provider">

            {
                providers.map((provider, i) => {

                    return (
                        <div key={provider.name + provider.id} className='radio-question'> 
                            <button
                                key={'provider' + provider.id}
                                className={(selectedProvider.id === provider.id ? "selected provider" : "provider") + ((provider?.providerName?.toLowerCase()?.endsWith('pcp')) ? ' pcp' : '') + (isMobile ? ' mobile' : '') }
                                onClick={() => handleProviderSelect(provider)}
                            >
                                <div className='svg' style={{position : 'absolute', top: '16px'}}>
                                    {selectedProvider.id === provider.id ? <Radiofill /> : <RadioDot />}
                                </div>

                                <div className={ (isMobile ? 'mobile ' : 'desktop ')+'data'}>
                                    <div className='prov-name'>           
                                        <label htmlFor={provider.name} className='name'>                     
                                                {getMarkDown(provider.name || '')}
                                        </label>   
                                        <label htmlFor={provider.speciality} className='specialty'>
                                                {getMarkDown(provider.speciality || '')}
                                        </label>
                                    </div> 
                                    {
                                        (provider?.providerName?.toLowerCase()?.endsWith('pcp'))
                                        ?
                                            <div className={'pcp-tab' + (isMobile ? ' mobile' : ' desktop')} >
                                                {getMarkDown(pcpText)}
                                            </div>
                                        : 
                                            <></>
                                    }
                                 </div>
                            </button>
                        </div>
                    )
                })
            }
        </div >
    );
}

export default ProviderToast;