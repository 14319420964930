import React, { useEffect } from 'react';
import '../../../stylesheets/triagebot/SummarySkeleton.scss';
import { useDetectMobile } from '../utils';

const SummarySkeleton = () => {

    const {isMobile} = useDetectMobile();

    return(
        <div className={'summary-skeleton card'}>
            <div className='skeleton-tabBar'>
                <div className={(isMobile ? 'mobile ': '') + 'loadAnim strip1'}>
                    <div className='anim'>&nbsp;</div>
                </div>
                <div className={(isMobile ? 'mobile ': '') + 'loadAnim strip2'}>
                    <div className='anim'>&nbsp;</div>
                </div>
            </div>
            <div className='loadAnim strip3'>
                <div className='anim'>&nbsp;</div>
            </div>
            <div className='loadAnim strip4'>
                <div className='anim'>&nbsp;</div>
            </div>
            <div className={(isMobile ? 'mobile ': '') + 'loadAnim strip5'}>
                <div className='anim'>&nbsp;</div>
            </div>
            <div className='loadAnim strip6'>
                <div className='anim'>&nbsp;</div>
            </div>
            <div className='loadAnim strip7'>
                <div className='anim'>&nbsp;</div>
            </div>
            <div className='loadAnim strip8'>
                <div className='anim'>&nbsp;</div>
            </div>
            <div className={(isMobile ? 'mobile ': '') + 'loadAnim strip9'}>
                <div className='anim'>&nbsp;</div>
            </div>
            <div className='loadAnim strip10'>
                <div className='anim'>&nbsp;</div>
            </div>
            <div className='loadAnim strip11'>
                <div className='anim'>&nbsp;</div>
            </div>
            <div className='loadAnim strip12'>
                <div className='anim'>&nbsp;</div>
            </div>
            <div className='loadAnim strip13'>
                <div className='anim'>&nbsp;</div>
            </div>
            <div className='loadAnim strip14'>
                <div className='anim'>&nbsp;</div>
            </div>
            
        </div>
    )
}
export default SummarySkeleton;