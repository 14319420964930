import React, { useEffect } from 'react';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Footer from '../cards/Footer';
import { getMarkDown, useDetectMobile } from '../utils';
import '../../../stylesheets/triagebot/Dialog.scss';

const ChangeOverDialog = ({ message, setShowChangeOverPromptDialog, isAccepted }) => {

    const {isMobile} = useDetectMobile();

    const headerText = message?.data?.[0]?.headerText;
    const externalSchedulingUrl = message?.data?.[1]?.externalSchedulingUrl;

    function getStyle(oElm, strCssRule) {
        var strValue = "";
        if (document.defaultView && document.defaultView.getComputedStyle) {
            strValue = document.defaultView.getComputedStyle(oElm, "").getPropertyValue(strCssRule);
        }
        else if (oElm.currentStyle) {
            strCssRule = strCssRule.replace(/-(\w)/g, function (strMatch, p1) {
                return p1.toUpperCase();
            });
            strValue = oElm.currentStyle[strCssRule];
        }
        return strValue;
    }

    useEffect(() => {
        setTimeout(() => {
            const cards = document.getElementsByClassName('turn');

            if (!cards.length) return;

            const cWidth = cards[cards.length - 1].offsetWidth;
            const dialog = document.querySelector('#change-dialog .MuiDialog-paper');

            if (!dialog) return;

            const padding = parseInt(getStyle(dialog, 'padding-left')) + parseInt(getStyle(dialog, 'padding-right'));

            dialog.style.width = (cWidth - padding) + 'px';
            dialog.style.minWidth = (cWidth - padding) + 'px';
        }, 1)
    })

    const handleButtonEffect = () => { 
        setShowChangeOverPromptDialog(false);

        setTimeout(() => {
            document.activeElement.blur();
        }, 0);
    }

    const RedirectDeepLink = () => {
        window.open(`${externalSchedulingUrl}`);
        handleButtonEffect();
        isAccepted(true);
    }

    return (
        <Dialog
            id='change-dialog'
            open={true}
            onClose={handleButtonEffect}
        >
            <div id="dialog-title">
                <h2 className='headerText'>{getMarkDown(headerText)}</h2>
            </div>
            
            <DialogActions id="dialog-footer">
                <Footer>
                    <button
                        className={isMobile ? 'mobile cancel-button' : 'cancel-button'}
                        onClick={handleButtonEffect}
                    >
                        Cancel
                    </button>
                    <button
                        className='continue-button'
                        onClick={RedirectDeepLink}
                    >
                        Continue
                    </button>
                </Footer>
            </DialogActions>
        </Dialog>
    )
};

export default ChangeOverDialog;