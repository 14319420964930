import React, { useState } from 'react';
import ChangeDialog from '../dialogs/ChangeDialog';
import "../../../stylesheets/triagebot/Footer.scss"
import { useDetectMobile } from '../utils';

const Footer = ({ children, showChangeButton, onAccepted, onDenied, disabled, divID }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const {isMobile}  = useDetectMobile();

    if (!(onAccepted instanceof Function))
        onAccepted = () => { };

    if (!(onDenied instanceof Function))
        onDenied = () => { };

    const dialogResponse = (response) => {
        response ? onAccepted() : onDenied();
        handleOnClose();
    }

    const handleChangeOutline=()=>{
        setIsDialogOpen(true);

        document.activeElement.blur();
    }

    const handleOnClose = () => {
        setIsDialogOpen(false);
        var buttonID = divID+'-button';
        var cancelButton = document.getElementById(buttonID);
        var currentElement = document.getElementById(divID);
        
        setTimeout(()=>{
            cancelButton.focus();
        }, 100); 

        if(currentElement) {
            currentElement.scrollIntoView({behavior: 'smooth',block: 'start'});
        }      
    }

    return (
        <div className='card-footer'>
            { children}
            {
                showChangeButton &&
                <button id={divID+'-button'}
                    className={(disabled ? 'disabled ' : '' ) + (isMobile ? 'mobile ' : 'desktop ') + 'change-button'}
                    onClick={handleChangeOutline}
                    disabled={disabled}
                >
                    Change
                </button>
            }
            {
                showChangeButton &&
                <ChangeDialog
                    isDialogOpen={isDialogOpen}
                    onClose={handleOnClose}
                    isAccepted={dialogResponse}
                    disabled={disabled}
                />
            }
        </div >
    )
}

export default Footer;