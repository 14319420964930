import React, { useState, createRef } from 'react';
import SymptomSearchMenu from './SymptomSearchMenu';
import * as Front from '../../../assets/Homunculus/Front';
import * as Back from '../../../assets/Homunculus/Back';
import { ReactComponent as RotateIcon } from '../../../assets/RotateIcon.svg';
import '../../../stylesheets/triagebot/SymptomSearchBody.scss';

const SymptomSearchBody = ({ message, tags, setTags, bodyPartHover, setBodyPartHover, disabled, HoverColor }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [bodyPart, setBodyPart] = useState(false);
    const [bodyPartQueryTerm, setBodyPartQueryTerm] = useState();
    const [showBack, setShowBack] = useState(false);

    const container = createRef();

    const age = (message?.data?.[0]?.userAge)
        ? message.data[0].userAge
        : 42;

    const gender = (message?.data[0]?.userSex)
        ? message.data[0].userSex
        : 'male';

    const rotateText = (message?.data?.[0]?.rotateText)
        ? message.data[0].rotateText
        : '';

    const inputDisabled = disabled;

    const handleBodyPartClick = (event, bpart, bPartQueryTerm) => {
        if (inputDisabled) return;

        setBodyPart(bpart);
        setBodyPartQueryTerm(bPartQueryTerm);
        setShowMenu(true);

        return false;
    }

    const tagListContains = (bodypart) => {
        bodypart = bodypart.toLowerCase();

        return tags.filter(tag => { return tag.body_parts.includes(bodypart) }).length;
    }

    const shouldBeHighlighted = (bodypart) => {
        return tagListContains(bodypart) || (bodypart.toLowerCase() === bodyPartHover.toLowerCase());
    }

    return (
        <div ref={container} className={'symptom-body'}>
            <SymptomSearchMenu
                message={message}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
                onClose={() => setShowMenu(false)}
                bodyPart={bodyPart}
                bodyPartQueryTerm={bodyPartQueryTerm}
                age={age}
                gender={gender}
                tags={tags}
                setTags={setTags}
                setBodyPartHover={setBodyPartHover}
                HoverColor={HoverColor}
            />
            {
                showBack
                    ?
                    <div className={inputDisabled ? 'disabled humonculous' : 'humonculous'} >
                        <Back.Body className='back body' />

                        <Back.Head
                            className={shouldBeHighlighted('head') ? 'back head highlight' : 'back head'}
                            onClick={(e) => { handleBodyPartClick(e, 'Head', 'Head') }}
                        />

                        <Back.Ears
                            className={shouldBeHighlighted('ears') ? 'back ears highlight' : 'back ears'}
                            onClick={(e) => { handleBodyPartClick(e, 'Ears', 'Ears') }}
                        />
                  
                        <Back.NeckOrThroat
                            className={shouldBeHighlighted('neck or throat') ? 'back neckorthroat highlight' : 'back neckorthroat'}
                            onClick={(e) => { handleBodyPartClick(e, 'Neck or Throat', 'Neck or throat') }}
                        />

                        <Back.NapeOfNeck
                            className={shouldBeHighlighted('nape of neck') ? 'back napeofneck highlight' : 'back napeofneck'}
                            onClick={(e) => { handleBodyPartClick(e, 'Nape of Neck', 'Nape of neck') }}
                        />

                        <Back.UpperArm
                            className={shouldBeHighlighted('upper arm') ? 'back upperarms highlight' : 'back upperarms'}
                            onClick={(e) => { handleBodyPartClick(e, 'Upper Arms', 'Upper arm') }}
                        />

                        <Back.Elbows
                            className={shouldBeHighlighted('elbow') ? 'back elbows highlight' : 'back elbows'}
                            onClick={(e) => { handleBodyPartClick(e, 'Elbow', 'Elbow') }}
                        />

                        <Back.Forearm
                            className={shouldBeHighlighted('forearm') ? 'back forearm highlight' : 'back forearm'}
                            onClick={(e) => { handleBodyPartClick(e, 'Forearm', 'Forearm') }}
                        />

                        <Back.Hands
                            className={shouldBeHighlighted('hand') ? 'back hands highlight' : 'back hands'}
                            onClick={(e) => { handleBodyPartClick(e, 'Hand', 'Hand') }}
                        />

                        <Back.Back
                            className={shouldBeHighlighted('back') ? 'back baack highlight' : 'back baack'}
                            onClick={(e) => { handleBodyPartClick(e, 'Back', 'Back') }}
                        />

                        <Back.LowerBack
                            className={shouldBeHighlighted('lower back') ? 'back lowerback highlight' : 'back lowerback'}
                            onClick={(e) => { handleBodyPartClick(e, 'Lower Back', 'Lower back') }}
                        />

                        <Back.Buttocks
                            className={shouldBeHighlighted('buttocks') ? 'back buttocks highlight' : 'back buttocks'}
                            onClick={(e) => { handleBodyPartClick(e, 'Buttocks', 'Buttocks') }}
                        />

                        <Back.Thighs
                            className={shouldBeHighlighted('thigh') ? 'back thighs highlight' : 'back thighs'}
                            onClick={(e) => { handleBodyPartClick(e, 'Thigh', 'Thigh') }}
                        />

                        <Back.Knees
                            className={shouldBeHighlighted('knee') ? 'back knees highlight' : 'back knees'}
                            onClick={(e) => { handleBodyPartClick(e, 'Knee', 'Knee') }}
                        />

                        <Back.LowerLegs
                            className={shouldBeHighlighted('lower leg') ? 'back lowerlegs highlight' : 'back lowerlegs'}
                            onClick={(e) => { handleBodyPartClick(e, 'Lower Legs', 'Lower leg') }}
                        />

                        <Back.Foot
                            className={shouldBeHighlighted('foot') ? 'back foot highlight' : 'back foot'}
                            onClick={(e) => { handleBodyPartClick(e, 'Foot', 'Foot') }}
                        />

                    </div>
                    :
                    <div className={inputDisabled ? 'disabled humonculous' : 'humonculous'}>
                        <Front.Body className='front body' />

                        <Front.Head
                            className={shouldBeHighlighted('head') ? 'front head highlight' : 'front head'}
                            onClick={(e) => { handleBodyPartClick(e, 'Head', 'Head') }}
                        />

                        <Front.Ears
                            className={shouldBeHighlighted('ears') ? 'front ears highlight' : 'front ears'}
                            onClick={(e) => { handleBodyPartClick(e, 'Ears', 'Ears') }}
                        />

                        <Front.Eyes
                            className={shouldBeHighlighted('eyes') ? 'front eyes highlight' : 'front eyes'}
                            onClick={(e) => { handleBodyPartClick(e, 'Eyes', 'Eyes') }}
                        />

                        <Front.Nose
                            className={shouldBeHighlighted('nose') ? 'front nose highlight' : 'front nose'}
                            onClick={(e) => { handleBodyPartClick(e, 'Nose', 'Nose') }}
                        />

                        <Front.Mouth
                            className={shouldBeHighlighted('oral cavity') ? 'front mouth highlight' : 'front mouth'}
                            onClick={(e) => { handleBodyPartClick(e, 'Oral Cavity', 'Oral cavity') }}
                        />

                        <Front.Neck
                            className={shouldBeHighlighted('neck or throat') ? 'front neck highlight' : 'front neck'}
                            onClick={(e) => { handleBodyPartClick(e, 'Neck or Throat', 'Neck or throat') }}
                        />

                        <Front.UpperArms
                            className={shouldBeHighlighted('upper arm') ? 'front upperarms highlight' : 'front upperarms'}
                            onClick={(e) => { handleBodyPartClick(e, 'Upper Arms', 'Upper arm') }}
                        />

                        <Front.Forearm
                            className={shouldBeHighlighted('forearm') ? 'front forearm highlight' : 'front forearm'}
                            onClick={(e) => { handleBodyPartClick(e, 'Forearm', 'Forearm') }}
                        />

                        <Front.Hands
                            className={shouldBeHighlighted('hand') ? 'front hands highlight' : 'front hands'}
                            onClick={(e) => { handleBodyPartClick(e, 'Hand', 'Hand') }}
                        />

                        <Front.Chest
                            className={shouldBeHighlighted('chest') ? 'front chest highlight' : 'front chest'}
                            onClick={(e) => { handleBodyPartClick(e, 'Chest', 'Chest') }}
                        />

                        <Front.UpperAbdomen
                            className={shouldBeHighlighted('upper abdomen') ? 'front upperabdomen highlight' : 'front upperabdomen'}
                            onClick={(e) => { handleBodyPartClick(e, 'Upper Abdomen', 'Upper abdomen') }}
                        />

                        <Front.MiddleAbdomen
                            className={shouldBeHighlighted('mid abdomen') ? 'front middleabdomen highlight' : 'front middleabdomen'}
                            onClick={(e) => { handleBodyPartClick(e, 'Middle Abdomen', 'Mid abdomen') }}
                        />

                        <Front.LowerAbdomen
                            className={shouldBeHighlighted('lower abdomen') ? 'front lowerabdomen highlight' : 'front lowerabdomen'}
                            onClick={(e) => { handleBodyPartClick(e, 'Lower Abdomen', 'Lower abdomen') }}
                        />

                        <Front.Thighs
                            className={shouldBeHighlighted('thigh') ? 'front thighs highlight' : 'front thighs'}
                            onClick={(e) => { handleBodyPartClick(e, 'Thigh', 'Thigh') }}
                        />

                        <Front.SexualOrgans
                            className={shouldBeHighlighted('sexual organs') ? 'front sexualorgans highlight' : 'front sexualorgans'}
                            onClick={(e) => { handleBodyPartClick(e, 'Sexual Organs', 'Sexual organs') }}
                        />

                        <Front.Knees
                            className={shouldBeHighlighted('knee') ? 'front knees highlight' : 'front knees'}
                            onClick={(e) => { handleBodyPartClick(e, 'Knee', 'Knee') }}
                        />

                        <Front.LowerLegs
                            className={shouldBeHighlighted('lower leg') ? 'front lowerlegs highlight' : 'front lowerlegs'}
                            onClick={(e) => { handleBodyPartClick(e, 'Lower Legs', 'Lower leg') }}
                        />

                        <Front.Foot
                            className={shouldBeHighlighted('foot') ? 'front foot highlight' : 'front foot'}
                            onClick={(e) => { handleBodyPartClick(e, 'Foot', 'Foot') }}
                        />
                    </div>
            }
            <div
                onClick={() => setShowBack(!showBack)}
                className='rotate'
            >
                <RotateIcon className='rotateIcon' transform={showBack ? 'rotate(180) translate(7 -1)' : 'rotate(0)'} />
                <span>{rotateText}</span>
            </div>
        </div>
    );
};

export default SymptomSearchBody;