import React from 'react';
import { ReactComponent as AlertIcon } from '../../../assets/Alert.svg';
import '../../../stylesheets/triagebot/Errors.scss';

const ErrorMessage = ({ message }) => {
    return (
        <div className='error card'>
            <div className='content'>
                <div className='icon'>
                    <AlertIcon />
                </div>
                <div className='body'>
                    {message.text || 'An error occurred.'}
                </div>
            </div>
        </div>
    );
}

export default ErrorMessage;