import React, { useState, useEffect } from 'react';
import { getMarkDown, useDetectMobile } from '../utils';
import TextField from '@mui/material/TextField';
import Footer from './Footer';
import '../../../stylesheets/triagebot/AgeCard.scss';

const AgeCardMessage = ({ message, sendMessage, disabled }) => {
    const [age, setAge] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [editing, setEditing] = useState(false);
    const [valid, setValid] = useState(false);

    const questionId = (message && message.data && message.data.length && message.data[0] && message.data[0].question_id)
        ? message.data[0].question_id
        : -1;

    const isDisabled = submitted || disabled;

    const {isMobile} = useDetectMobile();

    const ageChanged = (e) => {
        const age = parseInt(e.target.value.replace(/[^0-9]/g, ''));

        if (isNaN(age)) {
            setValid(false);
            setAge('');
            return;
        }

        if (age >=0 && age<=120) {
            setValid(true);
            setAge(age);
            return;
        }
    }

    const sendAgeMessage = () => {
        if (submitted) {
            sendMessage('rewind', questionId, questionId);
            setSubmitted(false);
            setEditing(true);
            return;
        }

        setSubmitted(true);

        editing
            ? sendMessage('message', `##edit@${message.data[0].question_id}@${age || 'none'}`, `##edit@${message.data[0].question_id}@${age || 'none'}`)
            : sendMessage('message', age, age)
    }
    
    useEffect(() => {
        let textbox = document.querySelector('div.age.card .MuiOutlinedInput-input');
        if (!isMobile && textbox)
            setTimeout(() => { textbox.focus(); }, 500);
    }, [isMobile])

    return (
        <div className={isDisabled ? 'disabled age card' : 'age card'}>
            <div className='content'>
                <div className='header'>
                    <h2 className='headerText'>{getMarkDown(message)}</h2>
                </div>
                <div className='body'>
                    <TextField
                        autoFocus={false}
                        type='tel' // Tel doesn't add unwanted up and down arrows, number does
                        className={(isMobile ? 'mobile ' : '') + 'age-input'}
                        variant="outlined"
                        placeholder={'Enter your age'}
                        value={age}
                        onChange={ageChanged}
                        InputProps={{ maxLength: 3 }}
                        disabled={isDisabled}
                    />
                </div>
            </div>
            <Footer
                showChangeButton={submitted && !message.isLastMessage}
                onAccepted={sendAgeMessage}
                onDenied={() => sendMessage('fastforward')}
                disabled={disabled}
                divID={questionId}
            >
                {
                    submitted ||
                    <button
                        disabled={!valid || isDisabled}
                        className='submit-button'
                        onClick={sendAgeMessage}
                        style={{display: submitted ? 'none' : ''}}
                        aria-hidden={isDisabled ? 'true': 'false'}
                    >
                        Next
                    </button>
                }
            </Footer>
        </div >
    );
};

export default AgeCardMessage;
