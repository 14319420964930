import React, { useState, useEffect } from 'react';
import { getMarkDown, useDetectMobile } from '../utils';
import TextField from '@mui/material/TextField';
import '../../../stylesheets/triagebot/TextInput.scss';

const TextInputMessage = ({ message, sendMessage, disabled }) => {
    const [input, setInput] = useState('');
    const [characterCount, setCharacterCount] = useState(0);
    const MAX_CHAR = 250;

    const {isMobile} = useDetectMobile();

    sendMessage = (typeof sendMessage === 'function')
        ? sendMessage
        : () => { }

    const subHeaderText = (message.data && message.data.length && message.data[0].subHeaderText)
        ? message.data[0].subHeaderText
        : ''

    const inputChanged = (e) => {
        setCharacterCount(e.target.value.length)
        setInput(e.target.value);
    }

    useEffect(() => {
        var textbox = document.getElementById('improve');
        setTimeout(()=>{
            textbox.focus();
        }, 500);
    }, []);

    return (
        <div id='NpsFeedback'>
                <label htmlFor="improve">
                    <div className='header' style= {{ color : `var(--primary-color)` }}>
                        <h2 className='headerText'>{getMarkDown(message)}</h2>
                    </div> 
                    <div className='subheader'>
                        {getMarkDown(subHeaderText)}
                    </div>
                </label>
                <div className='body'>
                    <TextField
                        className='freetextfield'
                        id="improve" 
                        aria-invalid="false"
                        variant="outlined"
                        multiline
                        minRows={3}
                        maxRows={3}
                        value={input}
                        onChange={inputChanged}
                        inputProps={{
                            maxLength: 250,
                            style: { 'marginBottom': 15 } 
                        }}
                        disabled={disabled}
                        fullWidth
                    />
                </div>
                <div className={(characterCount) ? 'counter' : 'ghosted counter'} >
                    {MAX_CHAR - characterCount}
                </div>
                <div className='footer'>
                    <button
                        className={isMobile ? 'mobile cancel-button' : 'cancel-button'}
                        onClick={() => sendMessage('go-back', 1, 1)}
                        disabled={disabled}
                    >
                        Back
                    </button>
                    <button
                        className={'continue-button'}
                        onClick={() => sendMessage('message', input, input)}
                        disabled={disabled}
                    >
                        Submit
                    </button>
                </div>
        </div >
    );
}

export default TextInputMessage;