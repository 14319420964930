import React, { useState, useEffect, useRef } from 'react';
import SummaryCauses from './SummaryCauses';
import SummaryOptions from './SummaryOptions';
import SummarySummary from './SummarySummary';
import SummaryRecommend from './SummaryRecommend';
import { ReactComponent as PrintIcon } from '../../../assets/print.svg';
import { addDetectUserEvents, removeDetectUserEvents, useDetectMobile } from '../utils';
import '../../../stylesheets/triagebot/Summary.scss';
import SummaryThankYou from './SummaryThankYou';

const SummaryMessage = ({ message, sendMessage, setToastMessage, disabled, HoverColor, setSuppressSummary, setPrintData }) => {
    const CARE_OPTIONS_TAB = 0;
    const SUMMARY_TAB = 1;
    const [selectedTab, setSelectedTab] = useState(CARE_OPTIONS_TAB);
    const [submitted, setSubmitted] = useState(false);

    var timer = null;

    const { isMobile } = useDetectMobile();

    const isDisabled = submitted || disabled;

    const tabs = message?.data?.[2].summaryCardContent?.tabHeaders
        ? message.data[2].summaryCardContent.tabHeaders
        : ['#######', '#######']

    const showSummaryOptions = message?.data?.[2].careOptionsData?.showCareOptions
        ? message.data[2].careOptionsData.showCareOptions
        : ''

    const tabSelected = (tab) => {
        setSelectedTab(tab);

        setTimeout(() => {
            document.activeElement.blur();
        }, 0);
    }

    const handleTimerOut = () => {
        //        sendMessage('message', 'NPS Timeout', 'NPS Timeout');
        removeDetectUserEvents(resetTimer);
    }

    const resetTimer = () => {
        timer && clearTimeout(timer);

        timer = setTimeout(handleTimerOut, 5000);
    }

    useEffect(() => {
        resetTimer();
        addDetectUserEvents(resetTimer);

        setSuppressSummary(true);

        return () => {
            clearTimeout(timer);
            removeDetectUserEvents(resetTimer);
        }
    }, [])

    function printWithSpecialFileName() {
        const today = (new Date()).toLocaleDateString('en-US');
        var tempTitle = document.title;
        document.title = `Symptom Checker Report (${today}).pdf`;
        window.print();
        document.title = tempTitle;

    }
    const messageRef = useRef();

    useEffect(() => {
        if (messageRef.current) {
            messageRef.current.scrollIntoView(
                {
                    behavior: 'smooth',
                    block: 'start'
                })
        }
    }, []);

    return (
        <div className={'tabbed summary card'} ref={messageRef}>
            <div className='tabbar'>
                <button
                    className={selectedTab === CARE_OPTIONS_TAB ? 'selected tab' : 'tab'}
                    onClick={() => tabSelected(CARE_OPTIONS_TAB)}
                    disabled={disabled}
                >
                    {tabs[0]}
                </button>
                <button
                    className={selectedTab === SUMMARY_TAB ? 'selected tab' : 'tab'}
                    onClick={() => tabSelected(SUMMARY_TAB)}
                    disabled={disabled}
                >
                    {tabs[1]}
                </button>
                {
                    isMobile
                        ?
                        <></>
                        :
                        <button
                            className='print'
                            onClick={printWithSpecialFileName}
                        >
                            <PrintIcon />
                        </button>
                }
            </div>

            <div className='tab-area' style={selectedTab === CARE_OPTIONS_TAB ? { display: 'block' } : { display: 'none' }} >
                <SummaryRecommend message={message} />
                <SummaryCauses message={message} setToastMessage={setToastMessage} />
                {
                    showSummaryOptions
                        ?
                        <SummaryOptions message={message} setToastMessage={setToastMessage} sendMessage={sendMessage} disabled={isDisabled} setSubmitted={submitted => setSubmitted(submitted)} HoverColor={HoverColor} />
                        :
                        <SummaryThankYou message={message} HoverColor={HoverColor} />
                }
            </div>

            <div className='tab-area' style={selectedTab === SUMMARY_TAB ? { display: 'block' } : { display: 'none' }}>
                <SummarySummary message={message} />
            </div>
        </div >);
}

export default SummaryMessage;
