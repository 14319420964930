import React from "react";
import { createRoot } from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TriageBotPage from "./components/triagebot/TriageBotPage";
import './stylesheets/fonts.css';
import "./stylesheets/index.css";

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<TriageBotPage />} />           
      </ Routes>
    </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
