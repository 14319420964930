import React, { useState, useEffect } from "react";
import Zippy from "./Zippy";
import { getMarkDown } from "../utils";
import "../../../stylesheets/triagebot/SummaryCauses.scss";
import PossibleCausesLink from "../dialogs/PossibleCausesLink";

const SummaryCauses = ({ message, setToastMessage }) => {
  const [openZippy, setOpenZippy] = useState(-1);
  const [showElement, setShowElement] = useState(null);

  const header = message?.data?.[2]?.possibleCausesContent?.headerText
    ? message.data[2].possibleCausesContent.headerText
    : "";

  const instructions = message?.data?.[2]?.possibleCausesContent?.instruction
    ? message?.data?.[2]?.possibleCausesContent?.instruction
    : "";

  const noCauses = message?.data?.[2]?.possibleCausesContent?.noPossibleCauses
    ? message.data[2].possibleCausesContent.noPossibleCauses
    : "";

  const sections = message?.data?.[2]?.possibleCausesContent?.sectionHeaders.length
    ? message.data[2].possibleCausesContent.sectionHeaders
    : ["", "", "",];

  const whyResult = message?.data?.[2]?.possibleCausesContent?.resultReason
    ? message.data[2].possibleCausesContent.resultReason
    : "";

  const formatCode = message?.data?.[0]?.FormatCode
    ? message.data[0].FormatCode.toLowerCase()
    : "unknown";

  const causes = message?.data?.[1]?.possible_causes
    ? message.data[1].possible_causes
    : [];

  const sourcesTxt = message?.data?.[2]?.possibleCausesContent?.sectionHeaders?.[3]
    ? message.data[2].possibleCausesContent.sectionHeaders[3]
    : '';

  causes.forEach((cause) => {
    cause.sources =
      cause && Array.isArray(cause.sources) ? cause.sources : [];
  });

  const titleCase = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
      })
      .join(" ");
  };

  const updateZippy = (e, cause, zippy) => {
    zippy === openZippy ? setOpenZippy(-1) : setOpenZippy(zippy);

    const el = document.getElementById("summary-header");
    if (el) setShowElement(el);
  };

  const defeatListingItems = (text) => {
    const dotLocation = text.indexOf(".");
    if (dotLocation < 0) return text;

    const number = text.slice(0, dotLocation) * 1;
    const dot = text.slice(dotLocation, dotLocation + 2);

    if (isNaN(number)) return text;
    if (dot !== ". ") return text;

    return text.slice(0, dotLocation) + "\\" + text.slice(dotLocation);
  };

  function isElement(o) {
    return typeof HTMLElement === "object"
      ? o instanceof HTMLElement //DOM2
      : o &&
      typeof o === "object" &&
      o.nodeType === 1 &&
      typeof o.nodeName === "string";
  }

  useEffect(() => {
    if (!isElement(showElement)) return;

    showElement.scrollIntoView({ behavior: "smooth", block: "start" });

    setShowElement(null);
  }, [showElement]);

  return (
    <div className="causes" key="causes">
      <div id="summary-header" className={"header " + formatCode} tabIndex={causes.length === 0 ? "-1": "0"}>
        <h2 className="headerText">{getMarkDown(header)}</h2>
      </div>

      {causes.length === 0 || <div className="instructions">{instructions}</div>}

      {causes.length ? (
        causes.map((cause, index) => {
          return (
            <Zippy
              key={"cause" + index}
              title={titleCase(
                cause.common_medline_name
                  ? cause.common_medline_name
                  : cause.common_name
              )}
              open={openZippy === index}
              onClick={(e) => {
                updateZippy(e, cause, index);
              }}

            >
              <div className="brief">
                {getMarkDown(cause.brief_summary)}
              </div>

              {
                cause.complete_summary
                  ?
                  <div>
                    <div className="section">
                      <h4 className="headerText">{sections[0]}</h4>
                    </div>
                    {getMarkDown(cause.complete_summary)}
                  </div>
                  : null
              }

              {
                cause.self_care_advice
                  ?
                  <div>
                    <div className="section">
                      <h4 className="headerText">{sections[1]}</h4>
                    </div>
                    {getMarkDown(cause.self_care_advice)}
                  </div>
                  : null
              }

              {
                cause.see_provider
                  ?
                  <div>
                    <div className="section">
                      <h4 className="headerText">{sections[2]}</h4>
                    </div>
                    {getMarkDown(cause.see_provider)}
                  </div>
                  : null
              }

              {
                cause.sources.length
                  ?
                  <Zippy
                    key={"sources" + index}
                    type="text"
                    title={sourcesTxt}
                  >
                    {cause.sources.map((source, i) => {
                      return (
                        <div
                          key={"cause" + index + "source" + i}
                          className="sources"
                        >
                          {getMarkDown(defeatListingItems(source))}
                        </div>
                      );
                    })}
                  </Zippy>
                  : ""
              }

              {
                cause.hasOwnProperty("explanationResponse")
                  ?
                  <div className="explanation">
                    <button
                      className="whyDidIButton"
                      style={{ color: `var(--primary-color)`, cursor: "pointer", fontFamily: 'var(--font-family)' }}
                      onClick={() =>
                        setToastMessage({
                          type: "causes",
                          header: cause.explanationResponse.header,
                          body: (
                            <PossibleCausesLink
                              message={cause.explanationResponse}
                            />
                          ),
                        })
                      }
                    >
                      {whyResult}
                    </button>
                  </div>
                  : null
              }
            </Zippy>
          );
        })
      ) : (
        <div className="no-causes">{getMarkDown(noCauses)}</div>
      )}
    </div>
  );
};

export default SummaryCauses;
