import React, { useState, useEffect } from 'react';
import Page from './Page';
import { getMarkDown } from '../utils';
import { ReactComponent as YesIcon } from '../../../assets/icon-yes-color.svg';
import { ReactComponent as NoIcon } from '../../../assets/icon-no-color.svg';
import '../../../stylesheets/triagebot/print/SummaryPrintable.scss';

const SummaryPrintable = ({ data, logo }) => {
    const [pages, setPages] = useState([]);

    const eVisitText = data?.data?.[2]?.summaryCardContent?.hasNoVirtualSlots
        ? data.data[2].summaryCardContent.hasNoVirtualSlots
        : "";

    const hasSlots = data?.data?.[0]?.hasSlots
        ? data.data[0].hasSlots
        : false;

    const triageLevel = data?.data?.[0]?.triageMapping?.triagelevel
        ? data.data[0].triageMapping.triagelevel
        : 'unknown';

    const triageText = data?.data?.[0]?.triageMapping?.description
        ? data.data[0].triageMapping.description
        : ''

    const recMessage = ((triageLevel === 'virtual_care') && !hasSlots) ? eVisitText : triageText

    // Possible Causes

    const pcHeader = data?.data?.[2]?.possibleCausesContent?.headerText
        ? '**' + data.data[2].possibleCausesContent.headerText + '**'
        : "";

    const pcNoCauses = data?.data?.[2]?.possibleCausesContent?.noPossibleCauses
        ? data.data[2].possibleCausesContent.noPossibleCauses
        : "";

    const pcSections = data?.data?.[2]?.possibleCausesContent?.sectionHeaders.length
        ? data.data[2].possibleCausesContent.sectionHeaders
        : ["", "", "",];

    const pcCauses = data?.data?.[1]?.possible_causes
        ? data.data[1].possible_causes
        : [];

    const symptoms1 = data?.data?.[1]?.chief_complaints
        ? data.data[1].chief_complaints
        : []

    const symptoms2 = data?.data?.[1]?.secondary_complaints
        ? data.data[1].secondary_complaints
        : []

    const symptoms = [...symptoms1, ...symptoms2]

    const sourcesTxt = data?.data?.[2]?.possibleCausesContent?.sectionHeaders?.[3]
        ? data.data[2].possibleCausesContent.sectionHeaders[3]
        : '';

    // Footer

    const footer = {
        disclaimer: data?.data?.[2]?.summaryCardContent?.pdfFooter
            ? data?.data?.[2]?.summaryCardContent?.pdfFooter
            : "",

        createdTxt: data?.data?.[2]?.summaryCardContent?.createdOn
            ? data?.data?.[2]?.summaryCardContent?.createdOn
            : ""

    }

    // Computed

    pcCauses.forEach((cause) => {
        cause.sources = (cause && Array.isArray(cause.sources)) ? cause.sources : [];
    });

    const pcDefeatListingItems = (text) => {
        const dotLocation = text.indexOf(".");
        if (dotLocation < 0) return text;

        const number = text.slice(0, dotLocation) * 1;
        const dot = text.slice(dotLocation, dotLocation + 2);

        if (isNaN(number)) return text;
        if (dot !== ". ") return text;

        return text.slice(0, dotLocation) + "\\" + text.slice(dotLocation);
    };

    function getHeights(el) {
        try {
            var styles = window.getComputedStyle(el);

            return {
                height: el.offsetHeight,
                marginTop: parseFloat(styles['marginTop']),
                marginBottom: parseFloat(styles['marginBottom'])
            }
        } catch (e) {
            return {
                height: -1,
                marginTop: 0,
                marginBottom: 0
            };
        }
    }

    const removeRemoveMes = () => {
        document.querySelectorAll('.remove-me').forEach(removable => {
            while (removable.firstChild)
                removable.parentNode.insertBefore(removable.firstChild, removable);

            removable.parentNode.removeChild(removable);
        })
    }

    const convertScrollingToPages = () => {
        let pages = [];
        let scrolling = document.getElementById('scrolling-summary');
        let sibling = scrolling.firstChild;
        // All sibling *MUST* be an elements. 
        // Wrap text nodes with a <p> or <div>.
        // React markdown can obfuscate margins.
        // So, be sure siblings comminicate child margins

        while (sibling) {
            // let height = 0;
            let page = [];
            let heights = getHeights(sibling);
            let height = heights.marginTop;
            let lastBottom = 0;

            while (sibling) {
                heights = getHeights(sibling);
                let collaspedMargin =
                    (lastBottom > 0)
                        ? (heights.marginTop > 0)
                            ? (heights.marginTop > 0)               // last has + margin, current has + 
                                ? (lastBottom >= heights.marginTop)
                                    ? 0                                  // 0 because we already added last bottom
                                    : heights.marginTop - lastBottom     // Add the difference of margins
                                : heights.marginTop                  // last has + margin, current has - 
                            : lastBottom
                        : heights.marginTop;                         // last has negative margin, current + or -

                let newHeight = height + collaspedMargin + heights.height + lastBottom;

                // If bigger than page create new page
                if (newHeight > 854) break;

                // Add elements to page; igore text nodes
                if (newHeight <= 854 && heights.height > -1) {
                    height = newHeight;
                    lastBottom = heights.marginBottom;
                    page.push(sibling);
                }

                // move to next sibling
                scrolling.removeChild(sibling);
                sibling = scrolling.firstChild;
            }

            pages.push(page);
        }

        setPages(pages);
    }

    useEffect(() => {
        if (!data) return;

        removeRemoveMes();
        convertScrollingToPages();
    }, [data])

    return (
        <div className='summary'>
            <div id='paged-summary' className='paged'>
                {
                    pages.map((page, i) => {
                        return <Page key={page + i} logo={logo} footer={footer} page={i + 1} body={page} />
                    })
                }
            </div>
            <div id='scrolling-summary' className='scrolling'>
                {
                    <div className='heading'>
                        {getMarkDown('Symptom Checker Report')}
                    </div>
                }
                {
                    getMarkDown('Based on your symptoms and medical record information, we recommend:')
                }
                {
                    <div id='recMesssage' className='recMessage'>
                        {getMarkDown(recMessage)}
                    </div>
                }
                <hr style={{ color: '#000000', borderWidth: '0.5px', marginBlockStart: '24px', marginBlockEnd: '24px' }} />
                {
                    <div className='heading'>
                        {getMarkDown('Summary of your Symptoms')}
                    </div>
                }
                <ul>
                    {
                        symptoms.map(symptom => { return <li key={symptom}>{symptom}</li> })
                    }
                </ul>
                <hr style={{ color: '#000000', borderWidth: '0.5px', marginBlockStart: '24px', marginBlockEnd: '24px' }} />
                {
                    <div className='heading'>
                        {getMarkDown(pcHeader)}
                    </div>
                }
                {
                    pcCauses.length ? (
                        pcCauses.map((cause, index) => {
                            return (
                                <div key={cause + index} className='remove-me'>
                                    <div className="common">
                                        {getMarkDown(cause.common_name)}
                                    </div>
                                    <div className="brief">
                                        {getMarkDown(cause.brief_summary)}
                                    </div>

                                    {
                                        cause.complete_summary
                                            ?
                                            <>
                                                <div className="section">
                                                    {pcSections[0]}
                                                </div>
                                                {getMarkDown(cause.complete_summary)}
                                            </>
                                            : null
                                    }
                                    {
                                        cause.self_care_advice
                                            ?
                                            <>
                                                <div className="section">{pcSections[1]}</div>
                                                {getMarkDown(cause.self_care_advice)}
                                            </>
                                            : null
                                    }
                                    {
                                        cause.see_provider
                                            ?
                                            <>
                                                <div className="section">{pcSections[2]}</div>
                                                {getMarkDown(cause.see_provider)}
                                            </>
                                            : null
                                    }
                                    {
                                        cause.hasOwnProperty("explanationResponse")
                                            ? <Reasons data={cause.explanationResponse} />
                                            : null
                                    }
                                    {
                                        cause.sources.length
                                            ?
                                            <div className='avoid-page-breaks'>
                                                <div className='section'>
                                                    {sourcesTxt}
                                                </div>
                                                {
                                                    cause.sources.map((source, i) => {
                                                        return (
                                                            <div
                                                                key={"cause" + index + "source" + i}
                                                                className="sources"
                                                            >
                                                                { getMarkDown(pcDefeatListingItems(source))}
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        ((index + 1) < pcCauses.length)
                                            ? <hr style={{ color: '#000000', borderWidth: '0.5px', marginBlockStart: '24px', marginBlockEnd: '24px' }} />
                                            : null
                                    }
                                </div>

                            );
                        })
                    )
                        :
                        (
                            <div className="no-causes">{getMarkDown(pcNoCauses)}</div>
                        )}
            </div>
        </div>
    );
};

// ***** Reasons why component
const Reasons = ({ data }) => {
    const header = data.header ? data.header : '';
    const subHeader = data.text ? data.text : '';
    const forReason = data.reasonsForHeader ? data.reasonsForHeader : '';
    const againstReason = data.reasonsAgainstHeader ? data.reasonsAgainstHeader : '';
    const positiveListResponse = data?.data?.supporting_evidence ? data.data.supporting_evidence : [];
    const negativeListResponse = data?.data?.conflicting_evidence ? data.data.conflicting_evidence : [];

    return (
        <>
            <div className='section'>{getMarkDown(header)}</div>
            <div>{getMarkDown(subHeader)}</div>

            <div className='reasons'>
                <div className='reason'>
                    {getMarkDown(forReason + ':')}
                    {
                        positiveListResponse.map((reason, i) => {
                            return (
                                <div key={reason + i}>
                                    <YesIcon className="icon" />
                                    {reason}
                                </div>
                            )
                        })
                    }
                </div>

                <div className='reason'>
                    {getMarkDown(againstReason + ':')}
                    {
                        negativeListResponse.map((reason, i) => {
                            return (
                                <div key={reason + i}>
                                    <NoIcon className="icon" />
                                    {reason}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default SummaryPrintable;
