import React from 'react';
import { getMarkDown } from '../utils';
import '../../../stylesheets/triagebot/SummaryRecommend.scss';

const SummaryRecommend = ({ message }) => {

  const eVisitText = message?.data?.[2]?.summaryCardContent?.hasNoVirtualSlots
    ? message.data[2].summaryCardContent.hasNoVirtualSlots
    : "";

  const hasSlots = message?.data?.[0]?.hasSlots
    ? message.data[0].hasSlots
    : false;

  const triageLevel = message?.data?.[0]?.triageMapping?.triagelevel
    ? message.data[0].triageMapping.triagelevel.toLowerCase()
    : 'unknown';

  const triageText = message?.data?.[0]?.triageMapping?.description
    ? message.data[0].triageMapping.description
    : ''

  const hyperlinks = message.hyperlinks
    ? message.hyperlinks
    : []

  // This will allow new-tab hyperlinks to show in recommendations
  const recMessage = {
    type: 'message',
    // virtual_care without available slots rerecommend as eVisit
    text: ((triageLevel === 'virtual_care') && !hasSlots) ? eVisitText : triageText,
    hyperlinks: hyperlinks
  }

  return (
    <div className='recomendation'>
      {
        getMarkDown(recMessage)
      }

    </div>
  );
};

export default SummaryRecommend;
