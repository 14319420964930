import React, { useEffect, useState, useRef } from 'react';
import Footer from './Footer';
import InfoButton from './InfoButton.js';
import { getMarkDown, getMarkDownWithInfoButton, useDetectMobile } from '../utils';
import '../../../stylesheets/triagebot/SingleSelect.scss';
import ChangeOverDialog from '../dialogs/ChangeOverDialog';

const PromptMessage = ({ message, sendMessage, sentMessage, setToastMessage, selection, disabled, allowEdits, keepAlive }) => {
    const [selected, setSelected] = useState(selection);
    const [submitted, setSubmitted] = useState(sentMessage);
    const [editing, setEditing] = useState(false);
    const [showInPersonChangeOverPromptDialog, setShowInPersonChangeOverPromptDialog] = useState(false);

    const explication = (message?.data?.[0]?.explication)
        ? message.data[0].explication
        : '';

    const header = message?.data?.[0]?.headerText 
            ?   message?.data?.[0]?.headerText 
            : '';

    const subHeaderText = (message?.data?.[0]?.subHeaderText)
        ? message.data[0].subHeaderText
        : '';

    const questionId = (message?.data?.[0]?.question_id)
        ? message.data[0].question_id
        : '';

    const infoMean = (message?.data?.[0]?.infoMeaning)
        ? message.data[0].subHeaderText
        : 'What does this mean?';

    const hasInfoLink = message?.data?.[0]?.info?.linkText;

    const changeOverPromptDialogInPerson = message?.data?.[1]?.changeoverPrompt;

    const infoType = hasInfoLink
        ? message.data[0].info.type
        : ''

    const infoLinkText = hasInfoLink
        ? message.data[0].info.linkText
        : ''

    const infoHeaderText = hasInfoLink
        ? message.data[0].info.headerText
        : ''

    const infoBody = hasInfoLink
        ? message.data[0].info.text
        : ''

    const infoLinkMessage = {
        type: infoType || '',
        header: infoHeaderText || '',
        body: infoBody || ''
    }

    const HaveCancelButton = message?.data?.[0]?.cancelButton
            ? message?.data?.[0]?.cancelButton
            : '';

    const HaveNextButton = message?.data?.[0]?.nextButton
            ? message?.data?.[0]?.nextButton 
            : '';

    const isDisabled = submitted || disabled;

    const isYesButton = (button) => {
        return button && button.value && ['present', 'yes'].includes(button.value.toLowerCase());
    }

    const isNoButton = (button) => {
        return button && button.value && ['absent', 'no'].includes(button.value.toLowerCase());
    }

    const isUnknownButton = (button) => {
        return button && button.value && ['unknown', "i don't know"].includes(button.value.toLowerCase());
    }

    const isYesNo = () => {
        return message.buttons.reduce((result, button) => {
            return result && (
                isYesButton(button)
                || isNoButton(button)
                || isUnknownButton(button)
            )
        }, true)
    }

    const prompt = useRef();

    const buttonPress = (event, button) => {
        if (submitted) return;

        if(HaveCancelButton || HaveNextButton){
            setSelected(button.value);
            return;
        }

        event.target.parentElement.classList.add('hide-unselected');
        event.target.classList.add('selected');

        setSelected(button.value);
        setSubmitted(!keepAlive);
        setEditing(false);

        if (button.type === "openUrl") {
            window.open(button.hyperlink, '_self');
            return;
        }

        editing
            ? sendMessage('message', `##edit@${message?.data?.[0]?.question_id}@${button.value || 'none'}`, `##edit@${message?.data?.[0]?.question_id}@${button.value || 'none'}`)
            : sendMessage('message', button.value || 'none', button.value || 'none');
    }

    const changeAccepted = () => {
        if ((submitted || questionId >= 0)) {
            sendMessage('rewind', questionId, questionId);
            setSubmitted(false);
            setEditing(true);
            setSelected('');
            return;
        }
    };

    useEffect(() => {
        setSubmitted(sentMessage);
        setSelected(selection);
    }, [sentMessage, selection])

    useEffect(() => {
        if (!selection)
            document.activeElement.blur();
    })

    useEffect(()=>{
        var singleselectbutton = document.getElementById('button-single-select')
        setTimeout(()=>{
            singleselectbutton.focus();
        }, 500);
    }, []);

    const {isMobile} = useDetectMobile();

    const CancelButton = message?.data?.[0]?.cancelButton;

    const cancelPress = () => {
        sendMessage('rewind', ['summaryPlaceHolder', 'emergency'], ['summaryPlaceHolder', 'emergency']);
        sendMessage('message', CancelButton.value, CancelButton.value);
    }

    const submitPress = (selected) => {
        if (submitted) return;

        if(changeOverPromptDialogInPerson!==undefined && (selected === "InPerson")){
            setShowInPersonChangeOverPromptDialog(true);
            return;
        }

        setSubmitted(true);

        sendMessage('message', selected, selected);
    }

    const dialogResponse = (response) => {
        if (response) {
            setSubmitted(true);

            return sendMessage('message', selected, selected);
        }
    }

    return (
        <div ref={prompt} className={(submitted ? 'disabled ' : '') + 'single-select card'}>
            <div className='content' >
                <div className='header' id={ questionId }>
                    <h2 className='headerText'>
                        {
                            explication
                                ? getMarkDownWithInfoButton(message.text, explication, setToastMessage, isDisabled)
                                : getMarkDown(message.text || header)
                        }
                    </h2>
                    {
                        subHeaderText &&
                        <div className='subheader'>
                            {getMarkDown(subHeaderText)}
                        </div>
                    }
                </div>
                <div className='body' role='group' aria-labelledby={ questionId }>
                    {
                        message.buttons.map((button, key) => {
                            const isSelected = (selected === button.value) && 'selected';
                            const icon = (isYesButton(button) && 'yes') ||
                                (isNoButton(button) && 'no') ||
                                (isUnknownButton(button) && 'idk')

                            return isYesNo()
                                ?
                                <div key={button.value} className='button-row' >
                                    <button
                                        key={button.value}
                                        className={isSelected + ' single-select ' + icon}
                                        onClick={(event) => buttonPress(event, button)}
                                        onMouseDown={(e) => e.preventDefault()}
                                        disabled={isDisabled}
                                        id='button-single-select'
                                    >
                                        <input name="care" id={button.displayText.toLowerCase()} className='single-select-input'/> 
                                        <label htmlFor={button.displayText.toLowerCase()}>{button.displayText}</label> 
                                    </button>
                                </div>
                                :
                                <div key={button.value} className='button-row' >
                                    <button
                                        className={isSelected + ' single-select'}
                                        onClick={(event) => buttonPress(event, button)}
                                        disabled={isDisabled}
                                        id='button-single-select'
                                    >
                                        <input name="care" id={button.displayText.toLowerCase()} className='single-select-input'/> 
                                        <label htmlFor={button.displayText.toLowerCase()}>{button.displayText}</label> 
                                    </button>
                                    {
                                        button.explication &&
                                        <InfoButton
                                            disabled={isDisabled}
                                            header={infoMean}
                                            explication={button.explication}
                                            onShowInfo={setToastMessage}
                                        />
                                    }
                                </div>
                        })
                    }
                    {
                        hasInfoLink &&
                        (isDisabled
                            ?
                            <a className={'disabled infoLink'}>
                                {infoLinkText}
                            </a>
                            :
                            <a
                                href={'#'}
                                className={'infoLink'}
                                onClick={() => { setToastMessage(infoLinkMessage) }}
                            >
                                {infoLinkText}
                            </a>
                        )
                    }
                </div>
            </div>
            {
                (submitted && questionId) &&
                <div style={{ marginTop: 24 }}>
                    <Footer
                        showChangeButton={allowEdits && !message.isLastMessage}
                        onAccepted={changeAccepted}
                        onDenied={() => sendMessage('fastforward')}
                        disabled={disabled}
                        divID={questionId}
                    >
                    </Footer>
                </div>
            }

            { (HaveCancelButton || HaveNextButton) &&
            <div style={submitted ? { marginTop : '0px' } : { marginTop: '24px' }} className={(isMobile ? 'mobile ' : '') + 'footer-next-cancel-button'}>
                <Footer>
                    <button
                        onClick={cancelPress}
                        className={isMobile ? 'mobile cancel-button' : 'cancel-button'}
                        disabled={isDisabled}
                    >
                        Cancel
                        </button>
                    <button
                        onClick={()=>submitPress(selected)}
                        className='continue-button'
                        disabled={isDisabled || !selected}
                    >
                        Next
                        </button>
                </Footer>
            </div>
            }

            {
                showInPersonChangeOverPromptDialog
                ?
                    <ChangeOverDialog
                        message={changeOverPromptDialogInPerson}
                        setShowChangeOverPromptDialog={setShowInPersonChangeOverPromptDialog}
                        isAccepted={dialogResponse}
                    />
                :
                    <></>
            }
        </div >
    );
}

export default PromptMessage;