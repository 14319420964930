import React, { useState, useEffect, useRef } from 'react';
import Footer from './Footer';
import { getMarkDown, getMarkDownWithInfoButton } from '../utils';
import '../../../stylesheets/triagebot/GroupMultiSelect.scss';

function getWindowDimensions() {
  const width = window.innerWidth;
  return width;
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const GroupMultiMessage = ({ message, sendMessage, setToastMessage, disabled }) => {
  const width = useWindowDimensions();

  const explication = (message.data && message.data.length && message.data[0].explication)
    ? message.data[0].explication
    : '';

  const headerText = (message && message.data && message.data.length && message.data[0].headerText)
    ? message.data[0].headerText
    : '';

  const subHeaderText = (message && message.data && message.data.length && message.data[0].subHeaderText)
    ? message.data[0].headerText
    : 'Select one response for each statement.';

  const questionId = (message && message.data && message.data.length && message.data[0] && message.data[0].question_id)
    ? message.data[0].question_id
    : '';

  const questions = (message && message.data && message.data.length)
    ? [].concat.apply([], message.data.filter(data => Array.isArray(data) && data.length && data[0].question && data[0].choices))
    : [];

  const [answers, setAnswers] = useState(new Array(questions.length).fill(-1));
  const [submitted, setSubmitted] = useState(false);
  const [editing, setEditing] = useState(false);

  const isCompleted = answers.filter(answer => answer > -1).length === answers.length;

  const isDisabled = submitted || disabled;

  const group = useRef();

  const choicePress = (question, value) => {
    if (isDisabled) return;

    let newAnswers = [...answers];
    newAnswers[question] = value;

    setAnswers(newAnswers);

    setTimeout(() => {
      document.activeElement.blur();
    }, 0);
  };

  const submitPress = (event) => {
    if (!isCompleted) return;

    if (submitted) {
      sendMessage('rewind', questionId, questionId);
      setSubmitted(false);
      setEditing(true);
      return;
    }

    var submitValue = '';

    submitValue = answers.map((answer, question) => {
      return (questions[question].choices[answer].id === undefined) ? questions[question].choices[answer].label : questions[question].choices[answer].id
    }).join();

    editing
      ? sendMessage('message', `##edit@${message.data[0].question_id}@${submitValue || 'none'}`, `##edit@${message.data[0].question_id}@${submitValue || 'none'}`)
      : sendMessage('message', submitValue || 'none', submitValue || 'none');

    setSubmitted(true);
  };

  useEffect(() => {
    var heading = document.querySelector('h2');
    setTimeout(() => {
      heading.focus();
    }, 500); 
}, [])

  return (
    <div ref={group} className={isDisabled ? 'disabled group-multi card' : 'group-multi card'}>
      <div className='content' >
        <div className='header'>
          <h2 className='headerText' tabIndex={isDisabled?"-1":"0"}>
          {
            explication
              ? getMarkDownWithInfoButton(headerText ? headerText : message.text, explication, setToastMessage, isDisabled)
              : getMarkDown(headerText ? headerText : message.text)
          }
          </h2>
          <div className='subheader'>
            {getMarkDown(subHeaderText)}
          </div>
        </div>
        <div className='body'>
          {
            questions.map((question, qkey) => {
              return (
                <div className={(width < 725) ? 'mobile-radio-bar' : 'radio-bar'} 
                  key={'question' + questionId + qkey} 
                  aria-labelledby={questionId + qkey}
                  role='group'
                >
                  <div className='radio-question' id={questionId + qkey}>
                    <div className='question-row' >
                      {
                        question.explication
                          ? getMarkDownWithInfoButton(question.question, question.explication, setToastMessage, isDisabled)
                          : getMarkDown(question.question)
                      }
                    </div>
                  </div>
                  <div className='radio-answer'>
                    {
                      question.choices.map((choice, ckey) => {
                        const isSelected = (answers[qkey] === ckey);
                        return (
                          <div
                            key={'question' + questionId + qkey + ckey}
                            onClick={() => choicePress(qkey, ckey)}
                            className='radio-button'
                          >
                            <button
                              id={choice.label+ qkey + ckey}
                              className={isSelected ? 'selected button' : 'button'}
                              disabled={isDisabled}
                              style={{cursor: 'pointer'}}
                            >
                              <div className='dot' />
                            </button>
                              <label htmlFor={choice.label + qkey + ckey}>{choice.label}</label> 
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>

      <Footer
        showChangeButton={questionId && submitted && !message.isLastMessage}
        onAccepted={submitPress}
        onDenied={() => sendMessage('fastforward')}
        disabled={disabled}
        divID={questionId}
      >
        {
          submitted ||
          <button
            onClick={submitPress}
            className='submit-button'
            disabled={!isCompleted || isDisabled}
            style={{display: isDisabled ? 'none' : ''}}
            aria-hidden={isDisabled ? 'true': 'false'}
          >
            Next
          </button>
        }
      </Footer>

    </div >
  );
};

export default GroupMultiMessage;
