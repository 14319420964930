import React, { useEffect, useState } from 'react';
import Footer from './Footer';
import { getMarkDown } from '../utils';
import { ReactComponent as TermsImage } from '../../../assets/Terms.svg';
import '../../../stylesheets/triagebot/Disclaimer.scss';

const DisclaimerMessage = ({ message, sendMessage, disabled }) => {
    const [submitted, setSubmitted] = useState(false);

    const headerText = (message.data && message.data.length && message.data[0].headerText)
        ? message.data[0].headerText
        : ''

    const subHeaderText = (message.data && message.data.length && message.data[0].subHeaderText)
        ? message.data[0].subHeaderText
        : ''

        const bodyText = (message.text)
        ? message.text
        : ''

    const credits = (message.data && message.data.length)
        ? message.data[0].credits
        : ''

    const buttons = (Array.isArray(message.buttons))
        ? message.buttons
        : []

    const isDisabled = submitted || disabled;

    const buttonPress = (event, button) => {
        if (submitted) return;

        setSubmitted(true);

        sendMessage('message', button.value, Date.now());
    }
    useEffect(() => {
        var hyperlinks = document.links;
        let i=0;
        while(i < hyperlinks.length) {
            hyperlinks[i].tabIndex = isDisabled && (hyperlinks[i].offsetParent?.className.includes("disclaimer card")) ? -1 : 0;
            i++;
        }
    })
    useEffect(() => {
        var heading = document.querySelector('h2');
        setTimeout(() => {
            heading.focus();
        }, 500);
    }, [])

    return (
        <div className={isDisabled?'disabled disclaimer card':'disclaimer card'}>
            <div className='content' >
                <div className='header'>
                    <h2 className='headerText' tabIndex={isDisabled ? '' : -1}>{getMarkDown(headerText)}</h2>
                </div>
                <div className='subHeader'>
                    {getMarkDown(subHeaderText)}
                </div>
                <div className='body'>
                    {getMarkDown(bodyText)}
                    <div className='image'>
                        <TermsImage className='image' />
                    </div>
                    <div className="credits">
                        {getMarkDown(credits)}
                    </div>
                </div>
            </div>
            <Footer>
                {buttons.map((button, key) => {
                    return (
                        <button
                            key={key}
                            className='submit-button'
                            disabled={isDisabled}
                            onClick={(event) => buttonPress(event, button)}
                        >
                            {button.displayText}
                        </button>
                    );
                })}
            </Footer>
        </div>
    );
}

export default DisclaimerMessage;
