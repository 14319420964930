import React from 'react';
import axios from "axios";
import ReactMarkdown from 'react-markdown';
import InfoButton from './cards/InfoButton';
import { useState, useEffect } from 'react';
import {ReactComponent as NewWindow} from '../../assets/NewWindow.svg';
import rehypeRaw from 'rehype-raw';

export const scrollToEnd = (behavior) => {
    setTimeout(() => {
        document.getElementById('endOfMessages').scrollIntoView({ behavior: behavior });
    }, 0)
}

export const getMarkDown = (message) => {
    if (!message) return "";

    if (typeof message !== 'string' && !(message instanceof String))
        if (typeof message.text !== 'string' && !(message.text instanceof String))
            return ""

    const markdown = (typeof message.text === 'string' || message.text instanceof String)
        ? preprocessMarkdown(message.text, message.hyperlinks)  // assume message is a message object
        : preprocessMarkdown(message)                           // assume message markdown string

    return <ReactMarkdown rehypePlugins={[rehypeRaw]} children={markdown} components={{ a: Hyperlink }} />;
}

const preprocessMarkdown = (markdown, hyperlinks) => {
    if (!markdown || !(typeof markdown === 'string' || markdown instanceof String))
        return markdown;

    let i = 0;

    markdown = markdown.replace(/<br><br>/g, '\n\n');
    markdown = markdown.replace(/<br>/g, '  \n');

    while (markdown.indexOf('##hyperlink') !== -1) {
        (Array.isArray(hyperlinks) && hyperlinks[i])
            ? markdown = markdown.replace('##hyperlink', `[${hyperlinks[i].display_text}](${hyperlinks[i].value}|${JSON.stringify(hyperlinks[i].ga_Identifier)})`)
            : markdown = markdown.replace('##hyperlink', '')
        i++;
    }

    while (markdown.indexOf('##hyperlink') !== -1) {
        (Array.isArray(hyperlinks) && hyperlinks[i])
            ? markdown = markdown.replace('##hyperlink', `[${hyperlinks[i].display_text}](${hyperlinks[i].value}|${JSON.stringify(hyperlinks[i].ga_Identifier)})`)
            : markdown = markdown.replace('##hyperlink', '')
        i++;
    }

    return markdown;
}

const Hyperlink = (props) => {
    const hyperlinkPressed = (e) => {
        if (Array.isArray(props) && props.length && props[0] instanceof Function) {
            e.preventDefault();
        }
    };

    return <span>
        {
            (props.href.startsWith('-'))
                ?
                <a
                    href=''
                    className='-anchor'
                >
                    {props.children}
                </a>
                :
                <a
                    href={props.href}
                    onClick={(e) => { hyperlinkPressed(e) }}
                    rel="noopener noreferrer"
                    target='_blank'
                    aria-describedby="NewWindow"
                >
                    {props.children}
                    <NewWindow 
                        style={{
                            "top": "4px",
                            "marginLeft": "4px",
                            "position": "relative",
                        }}
                    />
                    <span hidden id='NewWindow'>opens in new window</span>
                </a>
        }
    </span>
}

export const getMarkDownWithInfoButton = (text, explication, onShowInfo, disabled) => {
    return <InfoButton
        disabled={disabled}
        header='What does this mean?'
        text={text}
        explication={explication}
        onShowInfo={onShowInfo}
    />
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export function useDetectMobile(breakpoint = 640) {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());


    useEffect(() => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (!/android/i.test(userAgent)) {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, []);

    const { width } = windowDimensions;
    return { isMobile: width <= breakpoint };
}

export function useDetectTablet(breakpoint = 780) {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());


    useEffect(() => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (!/android/i.test(userAgent)) {
            function handleResize() {
                setWindowDimensions(getWindowDimensions());
            }

            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, []);

    const { width } = windowDimensions;
    return { isTablet: width <= breakpoint };
}


export const addDetectUserEvents = (callback) => {
    if (!(callback instanceof Function))
        callback = () => { };

    let bodyElement = document.getElementsByTagName('body')[0];

    bodyElement.addEventListener('touchmove', callback);
    bodyElement.addEventListener('touchmove', callback);
    bodyElement.addEventListener('mousemove', callback);
    bodyElement.addEventListener('wheel', callback);
}

export const removeDetectUserEvents = (callback) => {
    if (!(callback instanceof Function))
        callback = () => { };

    let bodyElement = document.getElementsByTagName('body')[0];

    bodyElement.removeEventListener('touchmove', callback)
    bodyElement.removeEventListener('click', callback)
    bodyElement.removeEventListener('mousemove', callback)
    bodyElement.removeEventListener('wheel', callback)
}

export const logEventHub = async (message, agg, retry) => {
    var success = false;
    retry = (retry) ? ++retry : 1;

    axios({
        method: "post",
        url: process.env.REACT_APP_EVENT_HUB_URL || 'https://eventhubs-services.optum.com/api/PublishLogEvent?Product=SympleNote',
        timeout: 0,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        data: {
            "agg": agg,
            "type": "error",
            "message": message,
            "env": process.env.REACT_APP_ASYNC_ENVIRONMENT
        }
    }).then((response) => {
        console.log('Logging to event hubs successful.\nMessage: ', message);
        success = (response.status === 200);
    }).catch((error) => {
    }).finally(() => {
        if (!success) {
            console.warn('Logging to event hubs failed.\nMessage: ', message);
            if (retry < 3)
                logEventHub(message, agg, retry)
        }
    })
}