import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DirectLine, } from 'botframework-directlinejs';
import * as Cards from './cards';
import ToastDialog from './dialogs/ToastDialog';
import DebugDialog from './dialogs/DebugDialog';
import { logEventHub } from './utils';
import { ConnectionStatus } from 'botframework-directlinejs';
import { connectErrCard, timeoutCard } from './messages'
import { thankyou, age, error, timeOut, testMsg, prompt, infotest, disclaimer, symptomCard, symptomsDurationCard,symptomsQuestionCard, confirm, confirmVirtual, summaryMessage, calendar, apterror, provider, welcome, treatmentplan, multiChoice, groupMulti, nps, singleNext } from './test-messages';
import '../../stylesheets/triagebot/TriageBotEngine.scss';

let directLine;
let directlineListener;
let directlineStatus;

let keyVaultUrl = process.env.REACT_APP_KEY_VAULT;
const reconnectUrl = process.env.REACT_APP_RECONNECT_URL;
const trigger = process.env.REACT_APP_TRIGGER;
const filter = process.env.REACT_APP_FILTER_ID;
const allowRestart = process.env.REACT_APP_ALLOW_RESTART;
const cdoName = process.env.REACT_APP_CDO_NAME;
const appId = process.env.REACT_APP_APP_ID;
const env = process.env.REACT_APP_ASYNC_ENVIRONMENT;
const params = new URLSearchParams(window.location.search);

const cardMap = {
  proselect: singleNext,
  groupmulti: groupMulti,
  confirm: confirm,
  confirmvirtual: confirmVirtual,
  summary: summaryMessage,
  appointment: calendar,
  apterror: apterror,
  provider: provider,
  welcome: welcome,
  plan: treatmentplan,
  multiselect: multiChoice,
  singleselect: prompt,
  symptoms: symptomCard,
  symptomsduration: symptomsDurationCard,
  symptomsquestion:symptomsQuestionCard,
  disclaimer: disclaimer,
  infotest: infotest,
  test: testMsg,
  timeout: timeOut,
  error: error,
  nps: nps,
  age: age,
  thankyou: thankyou
};

function TraigeBot({ setSummaryPrintData, updateProgress, showDebug, setShowDebug, setRestart, profile, showWelcomeIllustration, HoverColor }) {
  const [isStarting, setIsStarting] = useState(true); // Loading Directline
  const [isTriageOver, setIsTriageOver] = useState(false);
  const [isConversationOver, setIsConversationOver] = useState(false);
  const [supressScreen, setSupressScreen] = useState(false);
  const [isNPSAnswered, setIsNPSAnwered] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [suppressSummary, setSuppressSummary] = useState(false);
  const [messages, setMessages] = useState(
    params.has('startcard')
      ? [JSON.stringify(cardMap[params.get('startcard')])]
      : []
  );

  console.log('Triage Bot Engine Started..')
  // This is for debugging purposes only
  if (params.get('startcard') === 'summary')
    setTimeout(() => {
      setSummaryPrintData(cardMap['summary']);
    });

  let isAfterSummaryorTreatmentPlan = false;
  // use the commented code below to use mock LaunchCode, if new cdo arrives
  // process.env.REACT_APP_MOCKLAUNCHCODE?.split('|')?.[1] === '1'? process.env.REACT_APP_MOCKLAUNCHCODE?.split('|')[0] : (params.get('launch') || '')
  const launchCode = params.get('launch') || '';
  const profileId = profile?.id
    ? profile.id
    : 0

  const addHBIDtoDocument = () => {
    if (env.toLocaleLowerCase() !== 'production') {
      const el = document.getElementById('triage-bot');
      if (el)
        el.setAttribute('data-hbid', directLine.conversationId)
    }
  }

  // TODO: Move DirectLine connection logic to separate file
  const getDirectlineToken = async () => {
    console.log("Requesting DirectLine secret")

    try {
      const response = await axios({
        method: "get",
        url: keyVaultUrl,
        timeout: 0,
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
      })

      return { token: response.data }

    } catch (error) {
      console.error(error);
      return { error: error };
    }
  }

  const reconnectDirectline = async () => {
    console.log("Reconnecting to DirectLine");

    const reconnect = async () => {
      try {
        const response = await axios({
          method: "get",
          url: reconnectUrl + '&conversationId=' + directLine.conversationId,
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
          },
        })

        directLine.reconnect({
          token: response.data.data.token,
          streamUrl: response.data.data.streamUrl
        });

        console.log('New Direcline Object: ', directLine);

        // directLine = new DirectLine({
        //   token: response.data.data.token,
        //   streamUrl: response.data.data.streamUrl,
        //   conversationId: response.data.data.conversationId
        // });

        // console.log("New DirectLine Object: ", directLine)

      } catch (error) {
        console.error(error);
        return { error: error };
      }
    }

    return reconnect();
  }

  const listenForActivity = () => {
    directlineListener = directLine.activity$
      .filter((activity) => activity.type === 'message' && activity.from.id === filter)
      .subscribe((botMessage) => {
        try {
          let message = JSON.parse(botMessage.text);

          if (message?.type === 'echo')
            return sendMessage('message', message.text);

          if (message?.type === 'summary')
            setSummaryPrintData(message);

          message.id = parseInt(botMessage.id.split('|')[1]);

          if (message.data.length && message.data[0].current_step)
            updateProgress(message.data[0].current_step);

          setMessages(
            messages => {
              if ((['calender', 'calendar'].includes(message.type)) && (messages.length) && (['calender', 'calendar'].includes(JSON.parse(messages[messages.length - 1]).type)))
                messages.pop();

              return [...messages, JSON.stringify(message)].sort((a, b) => JSON.parse(a).id - JSON.parse(b).id)
            }
          );

          console.log(message);
        }
        catch (e) {
          console.log("UNRECOGNIZED MESSAGE:")
          console.log(botMessage);
          console.log("MESSAGE WAS TRANSLATED TO:")
          console.log(JSON.parse(makeDLMessage(botMessage)))
          setMessages(messages => [...messages, makeDLMessage(botMessage)].sort((a, b) => JSON.parse(a).id - JSON.parse(b).id));
        }
      });
  }

  const connectDirectLine = (launchCode) => {
    directlineStatus = directLine.connectionStatus$
      .subscribe(connectionStatus => {
        switch (connectionStatus) {
          case ConnectionStatus.Uninitialized:    // the status when the DirectLine object is first created/constructed
            console.log('DirectLine Status: Uninitialized');
            break;
          case ConnectionStatus.Connecting:       // currently trying to connect to the conversation
            console.log('DirectLine Status: Connecting');
            break;
          case ConnectionStatus.Online:           // successfully connected to the converstaion. Connection is healthy so far as we know.
            addHBIDtoDocument();
            listenForActivity();
            console.log('DirectLine Status: Online');
            break;
          case ConnectionStatus.ExpiredToken:     // last operation errored out with an expired token. Your app should supply a new one.
            console.log('DirectLine Status: Token Expired');
            reconnectDirectline();
            break;
          case ConnectionStatus.FailedToConnect:  // the initial attempt to connect to the conversation failed. No recovery possible.          
            setMessages([JSON.stringify(connectErrCard)])
            console.log('DirectLine Status: Failed to Connect');
            break;
          case ConnectionStatus.Ended:            // the bot ended the conversation
            console.log('DirectLine Status: Ended')
            break;
          default:
            break;
        }
      });

    (trigger && trigger.trim())
      ? directLine
        .postActivity({
          type: 'invoke',
          name: 'InitConversation',
          from: { id: 'myUserId', name: 'myUserName', },
          value: {
            triggeredScenario: {
              trigger: trigger,
              args: {
                launchCode: launchCode,
                profileid: profileId
              },
            },
          },
        })
        .subscribe(
          (id) => console.log(id),
          (error) => { console.log("ERROR: " + error) }
        )

      : directLine
        .postActivity({
          type: 'conversationUpdate',
          name: 'InitConversation',
          from: { id: 'myUserId', name: "myUserName" },
          membersAdded: [{ id: profileId, name: "SympleNoteMember" }],
          "value": [{
            triggeredScenario: {
              trigger: "trigger",
              args: {
                asyncProfileId: profileId,
                Launchcode: launchCode,
                CDOName: cdoName,
                AppId: appId,
                SmartonFHIR: 'test string'
              }
            }
          }]
        })
        .subscribe(
          (id) => console.log(id),
          (error) => { console.log("ERROR: " + error) }
        );

    // Refresh token periodically
    setInterval(reconnectDirectline, 600000);
  }

  const startDirectLine = async () => {
    if (!launchCode && !profileId) return;

    const directlineToken = (await getDirectlineToken()).token;

    if (!directlineToken) return;

    directLine = new DirectLine({
      secret: directlineToken,
      webSocket: true,
      pollingInterval: 1000,
      timeout: 60000,
      conversationStartProperties: { locale: 'en-US' }
    });

    connectDirectLine(launchCode);

    console.log("DirectLine Object:", directLine)
    console.log("CDO Name: ", cdoName);
    console.log("APP_ID: ", appId);
    console.log("LAUNCH CODE: ", launchCode, "\nPROFILE ID: ", profileId);
  };

  useEffect(() => {
    startDirectLine();
  }, []);

  useEffect(() => {
    const INITIAL_GAP = 0;

    const pageHeight = document.getElementById('page-body').offsetHeight;
    const scrollTop = document.getElementById('chat-panel-container').scrollTop;
    const lastMessageTop = document.getElementById('lastMessage') ? document.getElementById('lastMessage').offsetTop : 0;

    if (document.getElementById('lastMessage')) {
      document.getElementById('lastMessage').scrollIntoView({
          alignToTop: true, behavior: "smooth"
        });
    }    

    (document.getElementById('chat-panel').offsetHeight > (pageHeight - (INITIAL_GAP + 1)))
      ? document.getElementById('chat-background').style.minHeight = 'auto'
      : document.getElementById('chat-background').style.minHeight = (pageHeight - (INITIAL_GAP + 1)) + 'px';
  });

  const makeDLMessage = (message) => {
    // This function converts messages coming from infermetica into a standardized Optum healthbot messages
    try {
      var dlMessage = {
        type: message.type || 'error',
        text: message.text,
        id: parseInt(message.id ? message.id.split('|')[1] : Math.floor(Math.random() * 100000) + 100000)
      }

      if (!message.text) {
        message.type = 'error'
        message.text = 'This message is in an unrecognized format.'
        return JSON.stringify(dlMessage);
      }

      return JSON.stringify(dlMessage);
    }
    catch (e) {
      dlMessage = {
        type: 'error',
        text: 'This message is in an unrecognized format.',
        id: parseInt(Math.floor(Math.random() * 100000) + 100000)
      }

      return JSON.stringify(dlMessage);
    }
  };

  const rewindTo = (value, unsubmit) => {
    console.log("REWINDING TO: " + value);

    while (messages.length) {
      let lastMessage = JSON.parse(messages[messages.length - 1]);

      if (lastMessage.data && lastMessage.data.length && lastMessage.data[0] && (lastMessage.data[0].question_id === value)) break;

      messages.pop();

      // rewind to a string indentifying the card type
      if (lastMessage.type === value) {
        messages.push(JSON.stringify(lastMessage));
        break;
      }

      // rewind has an array indentifying the card types
      if (Array.isArray(value) && value.includes(lastMessage.type)) {
        messages.push(JSON.stringify(lastMessage));
        break;
      }
    }

    setMessages([...messages]);
  }

  const refreshProgress = (rewound) => {
    const localMessages = [...messages];

    const filtered = localMessages.filter((message) => {
      message = JSON.parse(message);
      return (message.data && message.data[0] && message.data[0].current_step);
    })

    // Update the progress with the last progress marker in filtered messages
    if ((typeof updateProgress === "function")) {
      filtered.length
        ? updateProgress(JSON.parse(filtered[filtered.length - 1]).data[0].current_step)
        : updateProgress('start')
    }
  }

  const isLastMessage = (index) => {
    if (index > messages.length - 1) return false;

    const card = JSON.parse(messages[index]);

    const cardBefore = (messages.length > 1)
      ? JSON.parse(messages[messages.length - 2])
      : { type: '' }

    // Never scroll to NPS after confirmation
    if ((index === messages.length - 1) && cardBefore.type === 'confirmation' && card.type === 'nps')
      return false;

    //  Is 2nd last and Confirmation?
    if (index === messages.length - 2 && (card.type === "confirmation"))
      return true;

    //  Is 2nd last and emergency?
    if (index === messages.length - 2 && (card.type === 'nine11' || card.type === 'emergency'))
      return true;

    // Is last message?
    return (index === messages.length - 1)
  }

  const sendMessage = (type, text, value) => {
    if (type === 'rewind') {
      rewindTo(value, true);
      refreshProgress(true);
      return;
    }

    if (type === 'fastforward') {
      document.getElementById('endOfMessages').scrollIntoView({ behavior: "smooth", block: 'start' });
      refreshProgress();
      return;
    }

    if (type === 'restart') {
      setRestart(true);
      return;
    }

    if (type === 'nps-answered') {
      setIsNPSAnwered(value);
      return;
    }

    console.log("SENDING TYPE: " + type + "   TEXT: " + text + "   VALUE: " + value);
    var sentAt = Date.now();

    directLine
      .postActivity({
        type: type,
        from: { id: 'myUserId', name: "myUserName" },
        text: text,
        value: value
      })
      .subscribe(
        id => {
          if (id.toLowerCase() === 'retry') {
            logEventHub('Directline response timed out.', 'FE - Directline')
            console.log(`RESPONSE TIMED OUT!\nWaited ${(Date.now() - sentAt) / 1000} seconds`)
          }
          else {
            console.log('Activity posted - ID: ', id);
          }
        },
        error => console.log('Error posting activity', error)
      );
  };

  const renderMessage = (message) => {
    if (isStarting) setIsStarting(false);
    
    switch (message.type.toLowerCase()) {
      case 'conglomo-selfcare':
        return <Cards.ConglomoSelfCare message={message} sendMessage={sendMessage}/>
      case 'welcome':
      case 'welcome-optumincubator':
        return <Cards.Welcome
          message={message}
          sendMessage={sendMessage}
          setToastMessage={setToastMessage}
          disabled={isTriageOver || isConversationOver}
          showWelcomeIllustration={showWelcomeIllustration}
        />

      case 'disclaimer':
        return <Cards.Disclaimer message={message} sendMessage={sendMessage} disabled={isTriageOver || isConversationOver} />

      case 'age':
        return <Cards.Age message={message} sendMessage={sendMessage} disabled={isTriageOver || isConversationOver} />

      case 'gender':
        return <Cards.SingleSelect
          message={message}
          sendMessage={sendMessage}
          setToastMessage={setToastMessage}
          disabled={!isAfterSummaryorTreatmentPlan && (isTriageOver || isConversationOver)}
          allowEdits={!isAfterSummaryorTreatmentPlan}
        />

      case 'symptomscard':
        return <Cards.SymptomSearch message={message} sendMessage={sendMessage} setToastMessage={setToastMessage} disabled={isTriageOver || isConversationOver} HoverColor={HoverColor} />
      
      case 'symptomsdurationcard':
        return <Cards.SymptomDuration message={message} sendMessage={sendMessage} setToastMessage={setToastMessage} disabled={isTriageOver || isConversationOver} HoverColor={HoverColor} />
      
      case 'symptomsquestioncard':
        return <Cards.SymptomQuestion message={message} sendMessage={sendMessage} setToastMessage={setToastMessage} disabled={isTriageOver || isConversationOver} HoverColor={HoverColor} />

      case 'confirmation':
        return <Cards.Confirm message={message} sendMessage={sendMessage} supressState={setSupressScreen} supressNPS={isNPSAnswered} setToastMessage={setToastMessage}/>

      case 'confirmvirtual':
        return (!supressScreen && <Cards.ConfirmVirtual
          message={message}
          sendMessage={sendMessage}
          disabled={isConversationOver}
        />)

      case 'calender':
      case 'calendar':
        return (!supressScreen && <Cards.AppointmentPicker
          message={message}
          sendMessage={sendMessage}
          disabled={isConversationOver}
        />)

      case 'multicalendar':
            return (!supressScreen && <Cards.MultiAppointmentPicker
              message={message}
              sendMessage={sendMessage}
              disabled={isConversationOver}
        />)          

      case 'yesno':
      case 'prompt':
        return <Cards.SingleSelect
          message={message}
          sendMessage={sendMessage}
          setToastMessage={setToastMessage}
          disabled={!isAfterSummaryorTreatmentPlan && (isTriageOver || isConversationOver)}
          allowEdits={!isAfterSummaryorTreatmentPlan}
        />

      case 'singleselect':
        return <Cards.SingleNext
          message={message}
          sendMessage={sendMessage}
          disabled={!isAfterSummaryorTreatmentPlan && (isTriageOver || isConversationOver)}
        />

      case 'multichoice':
        return <Cards.MultiSelect
          message={message}
          sendMessage={sendMessage}
          disabled={isTriageOver || isConversationOver}
        />

      case 'groupmultiple':
        return <Cards.GroupSelect
          message={message}
          sendMessage={sendMessage}
          setToastMessage={setToastMessage}
          disabled={isTriageOver || isConversationOver}
        />

      case 'nine11':
        if (!isTriageOver) setIsTriageOver(true);
        isAfterSummaryorTreatmentPlan = true;
        return <Cards.Nine11 message={message} />

      case 'emergency':
        if (!isTriageOver) setIsTriageOver(true);
        isAfterSummaryorTreatmentPlan = true;
        return <Cards.Emergency message={message} />

      case 'text':
      case 'message':
        if (message.text && message.text.toLowerCase().startsWith('oops'))
          return <Cards.Alert message={message} sendMessage={sendMessage} allowRestart={allowRestart} />
        if (message.text && message.text.toLowerCase().includes('conversation has timed out')) {
          if (!isConversationOver)
            setIsConversationOver(true);
          return <Cards.Alert message={timeoutCard} sendMessage={sendMessage} allowRestart={allowRestart} />
        }
        return <Cards.Text message={message} />;

      case 'summaryplaceholder':
        return (!suppressSummary && <Cards.SummarySkeleton />)

      case 'summary':
        if (!isTriageOver) setIsTriageOver(true);
        isAfterSummaryorTreatmentPlan = true;
        return <Cards.Summary message={message} sendMessage={sendMessage} setToastMessage={setToastMessage} disabled={isConversationOver} HoverColor={HoverColor} setSuppressSummary={setSuppressSummary} />

      case 'evisittos':
        return (!supressScreen && <Cards.EVisitTosSummary message={message} sendMessage={sendMessage} setToastMessage={setToastMessage} />)

      case 'treatmentplan':
        return <Cards.TreatmentPlan message={message} sendMessage={sendMessage} disabled={isConversationOver} HoverColor={HoverColor} />

      case 'nps':
        return <Cards.NPS message={message} sendMessage={sendMessage} />

      case 'thankyou':
        return <Cards.TextMessage message={message} sendMessage={sendMessage} />

      case 'textcard':
        return <Cards.TextCard message={message} sendMessage={sendMessage} />

      case 'freetext':
        return <Cards.FreeTextField message={message} sendMessage={sendMessage} disabled={isConversationOver} />

      case 'timeout':
      case 'connecterr':
      case 'oops':
        if (!isConversationOver) setIsConversationOver(true);
        return <Cards.Alert message={message} sendMessage={sendMessage} allowRestart={allowRestart} />

      case 'error':
        if (!isConversationOver) setIsConversationOver(true);
        return <Cards.Error message={message} />

      case 'testing':
      case 'soap_note_saved':
        return <></>;

      default:
        message.type = 'error';
        message.text = 'This message cannot be displayed.';
        return <Cards.Error message={message} />
    }
  };

  return (
    <div id='triage-bot'>
      <div className='chat-panel-container' id='chat-panel-container'>
        <div id='chat-panel' className='chat-panel'>
          {
            <div
              id='chat-background'
              className='chat-background'
            >
              {
                isStarting &&
                <div className="loading">
                  <svg className="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                  </svg>
                </div>
              }

              {
                messages.map((message, i) => {
                  message = JSON.parse(message);
                  var cardName = '';
                  if (message?.text === 'Analyzing your symptoms...') {
                    cardName = "AnalyseSymptoms";
                  }
                  if (cardName === '' && (message?.data?.[0]?.question_id === 'TreatmentPlan')) {
                    cardName = message.data[0].question_id;
                  }
                  if (cardName === '' && (message?.data?.[0]?.current_step === 'triage_complete_call911')) {
                    cardName = 'triageCall911';
                  }
                  if (cardName === '' && (message?.data?.[0]?.current_step === 'triage_complete_emergency')) {
                    cardName = 'triageEmergency';
                  }
                  if (cardName === '') {
                    cardName = (message?.data?.[0]?.question_id) || null;
                    if (cardName == null)
                      cardName = (message?.data?.[0]?.current_step) || null;
                  }
                  message.isLastMessage = isLastMessage(i);
                  return (
                    <div key={message.id} className='turn' id={cardName}>
                      {
                        message.isLastMessage &&
                        <div id='lastMessage'>&nbsp;</div>
                      }
                      {
                        renderMessage(message)
                      }
                    </div>
                  );
                })
              }
              <div id='endOfMessages'>&nbsp;</div>
            </div>
          }
        </div>
        {
          toastMessage && <ToastDialog message={toastMessage} fullscreen={toastMessage.fullscreen} onClose={toastMessage.onClose} close={() => setToastMessage(null)} />
        }
        {
          showDebug && <DebugDialog showDebug={showDebug} reconnectDirectline={reconnectDirectline} sendMessage={sendMessage} directLine={directLine} onClose={() => setShowDebug(false)} />
        }
      </div>
    </div>
  );
}

export default TraigeBot;
